import React, {useState, useEffect} from "react"
import "./About.css"
import {Link} from "react-router-dom"
import {db, storage} from "../utils/Firebase"
// import {getDocs, collection} from "firebase/firestore"
import {Content} from "../utils/UIFunctions"
import {getDownloadURL,ref} from "firebase/storage"

const About = () => {
    const [paypalImage, setPaypalImage] = useState(null)
    // const [series, setSeries] = useState([])
    const [content, setContent] = useState(null)

    useEffect(() => {
        Content(document.location.hostname, "About").then((newContent) => {
            setContent(newContent)
        })
    }, [])

    // useEffect(() => {
    //     const i = async () => {
    //         let newSeries = []
    //         let snapshot = await getDocs(collection(db, "series"))
    //
    //         if (!snapshot.empty) {
    //             for (let d of snapshot.docs) {
    //                 let thisData = d.data()
    //                 if (
    //                     thisData.pic_author &&
    //                     thisData.pic_author_url &&
    //                     thisData.pic_license &&
    //                     thisData.pic_license_url &&
    //                     thisData.pic_title &&
    //                     thisData.pic_url
    //                 ) {
    //                     newSeries.push(d.data())
    //                 }
    //             }
    //         }
    //         return setSeries(newSeries)
    //     }
    //     i()
    // }, [])

    useEffect(()=> {
        const i = async () =>
            setPaypalImage(await getDownloadURL(ref(storage, `images/btn_donateCC_LG.gif`)))
        i()
    },[])

    return (
        <div className="About">
            <div className="AboutCard" style={{fontSize:"0.9em"}}>
                <Link to={`/${window.location.hash}`}>
                    {content &&
                        content.length > 0 &&
                        content.find((o) => o.tag === "returnToSite").content}
                </Link>
            </div>
            <div className="AboutCard">
                <h1>
                    {content &&
                        content.length > 0 &&
                        content.find((o) => o.tag === "titleText").content}
                </h1>
                <hr/>
                <p className="AboutText">
                    {content &&
                        content.length > 0 &&
                        content.find((o) => o.tag === "AboutText01").content}
                </p>
                <p className="AboutText">
                    {content &&
                        content.length > 0 &&
                        content.find((o) => o.tag === "AboutText02").content}
                </p>
                <p className="AboutText">
                    {content &&
                        content.length > 0 &&
                        content.find((o) => o.tag === "AboutText03").content}
                </p>
                <p className="AboutText">
                    {content &&
                        content.length > 0 &&
                        content.find((o) => o.tag === "AboutText04").content}
                </p>
                <p className="AboutText">
                    {content &&
                        content.length > 0 &&
                        content.find((o) => o.tag === "AboutText05").content}
                </p>
                <p className="AboutText">
                    {content &&
                        content.length > 0 &&
                        content.find((o) => o.tag === "AboutText06").content}
                </p>
                <p className="AboutText">
                    {content &&
                        content.length > 0 &&
                        content.find((o) => o.tag === "AboutText07").content}
                </p>
                <p className="AboutText">
                    {content &&
                        content.length > 0 &&
                        content.find((o) => o.tag === "AboutText08").content}
                </p>
                <hr/>
                <p className="AboutText">
                    {content &&
                        content.length > 0 &&
                        content.find((o) => o.tag === "AboutTextDonate").content}
                </p>
                <p className="AboutText">
                    <a
                        href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=356C68SSMN7VG&currency_code=EUR&source=url"
                        target="_new"
                    >
                        <img src={paypalImage || null} alt="Donate with PayPal"/>
                    </a>
                </p>
                <hr/>
                <p className="AboutText">
                    {content &&
                        content.length > 0 &&
                        content.find((o) => o.tag === "AboutTextCopyright").content}
                </p>
            </div>
            {/*<div className="AboutCard">*/}
            {/*    <h2>*/}
            {/*        {" "}*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutCreditsTitle").content}*/}
            {/*        :*/}
            {/*    </h2>*/}
            {/*    <br/>*/}

            {/*        /!*<ul className="AboutText">*!/*/}
            {/*        /!*    {series &&*!/*/}
            {/*        /!*        series.length > 0 &&*!/*/}
            {/*        /!*        series.map((s) => {*!/*/}
            {/*        /!*            return (*!/*/}
            {/*        /!*                <li key={s.short}>*!/*/}
            {/*        /!*                    <b>{s.long}</b>:<br/>*!/*/}
            {/*        /!*                    <a href={s.pic_url} target="_new">*!/*/}
            {/*        /!*                        {s.pic_title}*!/*/}
            {/*        /!*                    </a>{" "}*!/*/}
            {/*        /!*                    by{" "}*!/*/}
            {/*        /!*                    <a href={s.pic_author_url} target="_new">*!/*/}
            {/*        /!*                        {s.pic_author}*!/*/}
            {/*        /!*                    </a>{" "}*!/*/}
            {/*        /!*                    (*!/*/}
            {/*        /!*                    <a href={s.pic_license_url} target="_new">*!/*/}
            {/*        /!*                        {s.pic_license}*!/*/}
            {/*        /!*                    </a>*!/*/}
            {/*        /!*                    )<br/>*!/*/}
            {/*        /!*                </li>*!/*/}
            {/*        /!*            )*!/*/}
            {/*        /!*        })}*!/*/}

            {/*        /!*    <li>*!/*/}
            {/*        /!*        <b>favicon</b>:<br/>*!/*/}
            {/*        /!*        <a*!/*/}
            {/*        /!*            href="https://commons.wikimedia.org/wiki/File:F1_tire_Pirelli_Cinturato_Blue.svg"*!/*/}
            {/*        /!*            target="_new"*!/*/}
            {/*        /!*        >*!/*/}
            {/*        /!*            F1 tire Pirelli Cinturato Blue*!/*/}
            {/*        /!*        </a>{" "}*!/*/}
            {/*        /!*        by{" "}*!/*/}
            {/*        /!*        <a*!/*/}
            {/*        /!*            href="https://commons.wikimedia.org/wiki/User:MiniPictures"*!/*/}
            {/*        /!*            target="_new"*!/*/}
            {/*        /!*        >*!/*/}
            {/*        /!*            MiniPictures*!/*/}
            {/*        /!*        </a>{" "}*!/*/}
            {/*        /!*        (*!/*/}
            {/*        /!*        <a*!/*/}
            {/*        /!*            href="https://creativecommons.org/licenses/by-sa/4.0/"*!/*/}
            {/*        /!*            target="_new"*!/*/}
            {/*        /!*        >*!/*/}
            {/*        /!*            CC BY-SA 4.0*!/*/}
            {/*        /!*        </a>*!/*/}
            {/*        /!*        )*!/*/}
            {/*        /!*    </li>*!/*/}
            {/*        /!*</ul>*!/*/}
            {/*    <p style={{fontSize:`0.9em`}}>*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutTechniques").content}*/}
            {/*    </p>*/}
            {/*</div>*/}
            {/*<div className="AboutCard" style={{fontSize:"0.8em", fontWeight:300}}>*/}
            {/*    <h1>*/}
            {/*        {" "}*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutPolicyTitle").content}*/}
            {/*    </h1>*/}

            {/*    <p className="AboutText">*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutPolicyText01").content}*/}
            {/*    </p>*/}

            {/*    <p className="AboutText">*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutPolicyText02").content}*/}
            {/*    </p>*/}

            {/*    <h2>*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutLogsTitle").content}*/}
            {/*    </h2>*/}

            {/*    <p className="AboutText">*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutLogsText01").content}*/}
            {/*    </p>*/}

            {/*    <h2>*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutCookiesTitle").content}*/}
            {/*    </h2>*/}

            {/*    <p className="AboutText">*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutCookiesText01").content}*/}
            {/*    </p>*/}

            {/*    <h2>*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutPoliciesTitle").content}*/}
            {/*    </h2>*/}

            {/*    <p className="AboutText">*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutPoliciesText01").content}*/}
            {/*    </p>*/}

            {/*    <p className="AboutText">*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutPoliciesText02").content}*/}
            {/*    </p>*/}

            {/*    <p className="AboutText">*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutPoliciesText03").content}*/}
            {/*    </p>*/}

            {/*    <h2>*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutThirdPartyTitle").content}*/}
            {/*    </h2>*/}

            {/*    <p className="AboutText">*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutThirdPartyText01").content}*/}
            {/*    </p>*/}

            {/*    <p className="AboutText">*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutThirdPartyText02").content}*/}
            {/*    </p>*/}

            {/*    <h2>*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutChildrenTitle").content}*/}
            {/*    </h2>*/}

            {/*    <p className="AboutText">*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutChildrenText01").content}*/}
            {/*    </p>*/}

            {/*    <p className="AboutText">*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutChildrenText02").content}*/}
            {/*    </p>*/}

            {/*    <h2>*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutOnlineTitle").content}*/}
            {/*    </h2>*/}

            {/*    <p className="AboutText">*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutOnlineText01").content}*/}
            {/*    </p>*/}

            {/*    <h2>*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutConsentTitle").content}*/}
            {/*    </h2>*/}

            {/*    <p className="AboutText">*/}
            {/*        {content &&*/}
            {/*            content.length > 0 &&*/}
            {/*            content.find((o) => o.tag === "AboutConsentText01").content}*/}
            {/*    </p>*/}
            {/*</div>*/}
        </div>
    )
}

export default About
