import {db} from "./Firebase"
import {doc, getDoc} from "firebase/firestore"

import moment from "moment-timezone"

export const LoadSerie = (seriesId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let d = await getDoc(doc(db, "series", seriesId))
            return resolve(d.data())
        } catch (error) {
            reject({error:"error loading series", details:error.message})
        }
    })
}

// TODO unused?
export const LoadWeekend = (seriesId, weekendId) => {
    return new Promise((resolve, reject) => {
        db.collection("series")
            .doc(seriesId)
            .collection("weekends")
            .doc(weekendId)
            .get()
            .then((weekend) => {
                resolve(weekend.data())
            })
            .catch((error) => {
                reject({error:"error loading race info", details:error.message})
            })
    })
}

// TODO unused?
export const LoadSessions = (seriesId, weekendId) => {
    return new Promise((resolve, reject) => {
        let toResolve = []
        db.collection("series")
            .doc(seriesId)
            .collection("weekends")
            .doc(weekendId)
            .collection("sessions")
            .orderBy("start")
            .get()
            .then((sessions) => {
                if (!sessions.empty) {
                    sessions.forEach((session) => {
                        if (session.data().end > Number(moment.tz("UTC").format("X")))
                            toResolve.push(session.data())
                    })
                    return toResolve
                }
            })
            .then((sessions) => {
                resolve(sessions)
            })
            .catch((error) => {
                reject({error:"error loading sessions", details:error.message})
            })
    })
}

// TODO unused?
export const LoadLocation = (locationId) => {
    return new Promise((resolve, reject) => {
        db.collection("locations")
            .doc(locationId)
            .get()
            .then((doc) => {
                resolve(doc.data())
            })
            .catch((error) => {
                reject({
                    error:"error loading location info",
                    details:error.message
                })
            })
    })
}

export const RoundTimes = (s, e) => {
    let now = Math.floor(Date.now() / 1000)
    let sessionStart = s - 3600
    let sessionEnd = e
    let graphStart
    if (sessionStart > now) {
        graphStart = sessionStart - (sessionStart % 3600)
    } else {
        graphStart = now - (now % 3600)
    }
    let graphEnd = sessionEnd - (sessionEnd % 3600)
    if (graphEnd < sessionEnd) {
        graphEnd += 3600
    }
    return [graphStart, graphEnd]
}

export const ConvertTimezone = (time, fromZone, toZone) =>
    moment(time, "X").tz(fromZone).tz(toZone)
