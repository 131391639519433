import React, { createContext, useReducer } from "react";

let LocationReducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return { ...state, locationData: action.locationData };
    default:
      return state;
  }
};
const initialLocation = [];
const LocationContext = createContext(initialLocation);
const LocationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LocationReducer, initialLocation);
  return (
    <LocationContext.Provider value={[state, dispatch]}>
      {children}
    </LocationContext.Provider>
  );
};

export { LocationContext, LocationProvider };
