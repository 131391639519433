import React, {Fragment, useEffect, useState} from "react"

import {useParams, Link, useHistory} from "react-router-dom"
import Header from "../Header/Header"
import "./Admin.css"

import {db} from "../utils/Firebase"
import {ConvertTimezone} from "../utils/DataFunctions"

import moment from "moment-timezone"

import "moment/locale/nl"
import {collection, onSnapshot, updateDoc, doc, setDoc} from "firebase/firestore"

moment.locale("nl")

const Series = () => {
    let {action, id} = useParams()
    const [series, setSeries] = useState([])

    useEffect(() => {
        const unsubscribe = onSnapshot(
            collection(
                db, "series"
            ),
            snapshot => {

                let toReturn = []
                for (let d of snapshot.docs) {
                    toReturn.push({id:d.id, ...d.data()})
                }
                toReturn.sort((a, b) => a.lastEnd - b.lastEnd)
                return setSeries(toReturn)
            })

        return () => {
            unsubscribe()
        }
    }, [])

    return (
        <Fragment>
            <Header/>
            {(!action || action === "list") && <SeriesList collection={series}/>}
            {action === "edit" && id && <SeriesEdit id={id} collection={series}/>}
            {action === "add" && <SeriesAdd/>}
        </Fragment>
    )
}

const SeriesList = ({collection}) => {
    return (
        <table className="ListTable">
            <thead>
            <tr>
                <td colSpan={3}>
                    <span className="AdminTitle">series</span>{" "}
                    <Link to="/admin/series/add">add</Link>
                </td>
            </tr>
            </thead>
            <tbody>
            {collection
                ? Object.keys(collection).map((i) =>
                    <tr className="ListRow" key={i}>
                        <td className="ListLineCell">
                            <Link to={`/admin/series/edit/${collection[i].id}`}>
                                {collection[i].long}
                            </Link>
                        </td>
                        <td className="ListLineCell">{collection[i].short}</td>
                        <td className="ListLineCell"><Link
                            to={`/admin/weekends/list/${collection[i].id}`}>{collection[i].lastEnd ?
                            <span>{ConvertTimezone(
                                collection[i].lastEnd,
                                "UTC",
                                "Europe/Amsterdam"
                            ).format("DD MMMM YYYY")}
                            </span> : <span style={{color:"red"}}>n/a</span>}</Link>
                        </td>
                    </tr>)
                : null}
            </tbody>
        </table>
    )
}

const SeriesEdit = ({id, collection}) => {
    const [thisSerie, setThisSerie] = useState(null)
    const history = useHistory()

    useEffect(() => {
        collection &&
        collection.length > 0 &&
        !thisSerie &&
        setThisSerie(collection.find((x) => x.id === id))
    }, [collection, id, thisSerie])

    const makeChange = (e) =>
        setThisSerie({
            ...thisSerie,
            [e.target.name]:
                String(Number(e.target.value)) === e.target.value
                    ? Number(e.target.value)
                    : e.target.value
        })

    const saveChange = async () => {
        await updateDoc(
            doc(
                db, "series", thisSerie.id
            ), thisSerie
        )
        return history.push("/admin/series/list")
    }

    return thisSerie ? (
        <div className="EditLine">
            <form>
                <input
                    type="text"
                    name="long"
                    className="EditInputText"
                    value={thisSerie.long}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;<i>long name</i>
                <br/>
                <input
                    type="text"
                    name="short"
                    className="EditInputText"
                    value={thisSerie.short}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;<i>short name</i>
                <br/>
                <input
                    type="button"
                    className="EditInputButton"
                    value="opslaan"
                    onClick={() => saveChange()}
                />
                &nbsp;
                <input
                    type="button"
                    className="EditInputButton"
                    value="herstel"
                    onClick={() => setThisSerie(null)}
                />
                &nbsp;
                <input
                    type="button"
                    className="EditInputButton"
                    value="lijst"
                    onClick={() => history.push("/admin/series/list")}
                />
                &nbsp;
            </form>
        </div>
    ) : (
        <div className="EditLine">serie not found</div>
    )
}

const SeriesAdd = () => {
    const [thisSerie, setThisSerie] = useState({long:"", short:"", id:""})
    const history = useHistory()

    const makeChange = (e) => {
        if (e.target.name === "id") {
            return setThisSerie({
                ...thisSerie,
                id:e.target.value
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f\u0020\u0027\u002d\u002e\u002f]/g, "")
                    .toLowerCase()
            })
        } else {
            return setThisSerie({
                ...thisSerie,
                [e.target.name]:
                    String(Number(e.target.value)) === e.target.value
                        ? Number(e.target.value)
                        : e.target.value
            })
        }
    }

    const saveChange = async () => {
        await setDoc(
            doc(
                db, "series", thisSerie.id
            ),
            thisSerie,
            {merge:true}
        )
        return history.push(`/admin/series/list`)
    }

    return thisSerie ? (
        <div className="EditLine">
            <form>
                <input
                    type="text"
                    name="id"
                    className="EditInputText"
                    value={thisSerie.id}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;<i>short id</i>
                <br/>
                <input
                    type="text"
                    name="long"
                    className="EditInputText"
                    value={thisSerie.long}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;<i>long name</i>
                <br/>
                <input
                    type="text"
                    name="short"
                    className="EditInputText"
                    value={thisSerie.short}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;<i>short name</i>
                <br/>
                <input
                    type="button"
                    className="EditInputButton"
                    value="opslaan"
                    onClick={() => saveChange()}
                />
                &nbsp;
                <input
                    type="button"
                    className="EditInputButton"
                    value="lijst"
                    onClick={() => history.push("/admin/series/list")}
                />
                &nbsp;
            </form>
        </div>
    ) : (
        <div className="EditLine">array not found</div>
    )
}

export default Series
