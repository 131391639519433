import React, {Fragment, useEffect, useState} from "react"

import {useParams, Link, useHistory} from "react-router-dom"
import Header from "../Header/Header"
import "./Admin.css"

import moment from "moment-timezone"

import {db} from "../utils/Firebase"
import {collection, onSnapshot, updateDoc, setDoc, doc, getDoc} from "firebase/firestore"

import {ConvertTimezone} from "../utils/DataFunctions"

const Sessions = () => {
    let {action, serie, weekend, session} = useParams()
    const [sessions, setSessions] = useState([])
    const [sessionTimezone, setSessionTimezone] = useState(null)
    const [defaultSessions, setDefaultSessions] = useState([])

    useEffect(() => {
        const i = async () => {
            let d = await getDoc(
                doc(
                    db, "series", serie)
            )
            return setDefaultSessions(d.data().defaultSessions || [])
        }
        i()
    }, [serie])

    useEffect(() => {
        const i = async () => {
            let w = await getDoc(
                doc(
                    db, "series", serie, "weekends", weekend)
            )
            let l = await getDoc(
                doc(
                    db, "locations", w.data().location)
            )
            return setSessionTimezone(l.data().timezone)
        }
        i()
    }, [serie, weekend])

    useEffect(() => {
        const unsubscribe = onSnapshot(
            collection(
                db, "series", serie, "weekends", weekend, "sessions"
            ),
            snapshot => {
                let toReturn = []
                for (let d of snapshot.docs) {
                    toReturn.push({id:d.id, ...d.data()})
                }
                toReturn.sort((a, b) => a.start - b.start)
                return setSessions(toReturn)
            }
        )
        return () => {
            unsubscribe()
        }
    }, [serie, weekend])

    const autoFill = async () => {
        if (sessions.length === 0 && defaultSessions.length > 0) {
            for (let session of defaultSessions) {
                await setDoc(
                    doc(
                        db, "series", serie, "weekends", weekend, "sessions", session.id
                    ),
                    {...session, start:0, end:0},
                    {merge:true}
                )
            }
            return true
        } else {
            return true
        }
    }

    return (
        <Fragment>
            <Header/>
            {(!action || action === "list") && (
                <SessionsList
                    collection={sessions}
                    weekend={weekend}
                    serie={serie}
                    timezone={sessionTimezone}
                    defaultSessions={defaultSessions}
                    autoFill={() => autoFill()}
                />
            )}
            {action === "edit" && serie && weekend && session && (
                <SessionsEdit
                    serie={serie}
                    weekend={weekend}
                    session={session}
                    collection={sessions}
                    timezone={sessionTimezone}
                    defaultSessions={defaultSessions}
                />
            )}
            {action === "add" && serie && (
                <SessionsAdd
                    weekend={weekend}
                    serie={serie}
                    timezone={sessionTimezone}
                />
            )}
        </Fragment>
    )
}

const SessionsList = ({
                          collection,
                          serie,
                          weekend,
                          timezone,
                          defaultSessions,
                          autoFill
                      }) =>
    <Fragment>
        <table className="ListTable">
            <thead>
            <tr>
                <td colSpan={4}>
          <span className="AdminTitle">
            sessions for {serie}/{weekend}
          </span>{" "}
                    <Link to={`/admin/sessions/add/${serie}/${weekend}`}>add</Link> |
                    {collection.length === 0 && defaultSessions.length > 0 && <Fragment>
              <span
                  style={{
                      color:"white",
                      textDecoration:"underline",
                      cursor:"pointer"
                  }}
                  onClick={() => autoFill()}
              >
                autofill
              </span>{" "}
                        |{" "}
                    </Fragment>}
                    <Link to={`/admin/weekends/list/${serie}`}>back to {serie}</Link>
                </td>
            </tr>
            </thead>
            <tbody>
            {collection && timezone
                ? Object.keys(collection).map((i) =>
                    <tr className="ListRow" key={i}>
                        <td className="ListLineCell">
                            <Link
                                to={`/admin/sessions/edit/${serie}/${weekend}/${collection[i].id}`}
                            >
                                {collection[i].long}
                            </Link>
                        </td>
                        <td className="ListLineCell">{collection[i].short}</td>
                        <td className="ListLineCell">
                            {ConvertTimezone(
                                collection[i].start,
                                "UTC",
                                timezone
                            ).format("DD-MM-YYYY HH:mm")}
                            &nbsp;
                            <i>
                                (
                                {ConvertTimezone(
                                    collection[i].start,
                                    "UTC",
                                    "Europe/Amsterdam"
                                ).format("HH:mm")}
                                )
                            </i>
                        </td>
                        <td className="ListLineCell">
                            {ConvertTimezone(
                                collection[i].end,
                                "UTC",
                                timezone
                            ).format("DD-MM-YYYY HH:mm")}
                            &nbsp;
                            <i>
                                (
                                {ConvertTimezone(
                                    collection[i].end,
                                    "UTC",
                                    "Europe/Amsterdam"
                                ).format("HH:mm")}
                                )
                            </i>
                        </td>
                    </tr>)
                : null}
            </tbody>
        </table>
    </Fragment>

const SessionsEdit = ({serie, weekend, session, collection, timezone, defaultSessions}) => {
    const [thisSession, setThisSession] = useState(null)
    const history = useHistory()
    const [sessionDuration,setSessionDuration]=useState(60)

    useEffect(()=>{
        if (defaultSessions && defaultSessions.length>0 && session) {
            let s=defaultSessions.find(o=>o.id===session)
            if (s && s.d) setSessionDuration(s.d)
        }
    },[defaultSessions,session])

    useEffect(() => {
        collection &&
        collection.length > 0 &&
        !thisSession &&
        setThisSession(collection.find((x) => x.id === session))
    }, [collection, session, thisSession])

    const makeChange = async (e) => {
        if (e.target.name === "start" || e.target.name === "end") {
            e.persist()
            let utcTime = Number(
                moment
                    .tz(
                        e.target.value,
                        "DD-MM-YYYY HH:mm",
                        serie === "nascar" || serie === "indycar"
                            ? "America/New_York"
                            : timezone
                    )
                    .tz("UTC")
                    .format("X")
            )
            // console.log(e.target.value, utcTime);
            if (e.target.name === "start" && thisSession.end === 0) {
                document.getElementsByName('end')[0].value = ConvertTimezone(
                    utcTime + sessionDuration*60,
                    "UTC",
                    serie === "nascar" || serie === "indycar"
                        ? "America/New_York"
                        : timezone
                ).format("DD-MM-YYYY HH:mm")
                return setThisSession({
                    ...thisSession,
                    [e.target.name]:utcTime,
                    end:utcTime + sessionDuration*60
                })
            } else {
                return setThisSession({
                    ...thisSession,
                    [e.target.name]:utcTime
                })
            }
        } else {
            return setThisSession({
                ...thisSession,
                [e.target.name]:
                    String(Number(e.target.value)) === e.target.value
                        ? Number(e.target.value)
                        : e.target.value
            })
        }
    }

    const saveChange = async () => {
        await updateDoc(
            doc(
                db, "series", serie, "weekends", weekend, "sessions", thisSession.id
            ),
            thisSession
        )
        return history.push(`/admin/sessions/list/${serie}/${weekend}`)
    }

    return thisSession && timezone ? (
        <div className="EditLine">
            <p>
                <Link to={`/admin/sessions/list/${serie}/${weekend}`}>
                    back to {weekend}
                </Link>
            </p>
            <form>
                <input
                    type="text"
                    name="long"
                    className="EditInputText"
                    value={thisSession.long}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;<i>long name</i>
                <br/>
                <input
                    type="text"
                    name="short"
                    className="EditInputText"
                    value={thisSession.short}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;<i>short name</i>
                <br/>
                <input
                    type="text"
                    name="start"
                    className={
                        serie === "nascar" || serie === "indycar"
                            ? "NASCARInputText"
                            : "EditInputText"
                    }
                    defaultValue={ConvertTimezone(
                        thisSession.start,
                        "UTC",
                        serie === "nascar" || serie === "indycar"
                            ? "America/New_York"
                            : timezone
                    ).format("DD-MM-YYYY HH:mm")}
                    onBlur={(e) => makeChange(e)}
                    autoFocus
                    onFocus={(e)=>e.target.select()}
                />
                &nbsp;
                <i>
                    start (
                    {serie === "nascar" || serie === "indycar" ? <b>Eastern</b> : "local"}
                    )
                </i>
                <br/>
                <input
                    type="text"
                    name="end"
                    className={
                        serie === "nascar" || serie === "indycar"
                            ? "NASCARInputText"
                            : "EditInputText"
                    }
                    defaultValue={ConvertTimezone(
                        thisSession.end,
                        "UTC",
                        serie === "nascar" || serie === "indycar"
                            ? "America/New_York"
                            : timezone
                    ).format("DD-MM-YYYY HH:mm")}
                    onBlur={(e) => makeChange(e)}
                />
                &nbsp;
                <i>
                    end (
                    {serie === "nascar" || serie === "indycar" ? <b>Eastern</b> : "local"}
                    )
                </i>
                <br/>
                <input
                    type="button"
                    className="EditInputButton"
                    value="opslaan"
                    onClick={() => saveChange()}
                />
                &nbsp;
                <input
                    type="button"
                    className="EditInputButton"
                    value="herstel"
                    onClick={() => setThisSession(null)}
                />
                &nbsp;
                <input
                    type="button"
                    className="EditInputButton"
                    value="lijst"
                    onClick={() =>
                        history.push(`/admin/sessions/list/${serie}/${weekend}`)
                    }
                />
            </form>
        </div>
    ) : (
        <div className="EditLine">session not found</div>
    )
}

const SessionsAdd = ({serie, weekend}) => {
    const [thisSession, setthisSession] = useState({
        id:"",
        long:"",
        short:"",
        start:0,
        end:0
    })
    const history = useHistory()

    const makeChange = (e) => {
        if (e.target.name === "id") {
            return setthisSession({
                ...thisSession,
                id:e.target.value
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f\u0020\u0027\u002d\u002e\u002f]/g, "")
                    .toLowerCase()
            })
        } else {
            return setthisSession({
                ...thisSession,
                [e.target.name]:
                    String(Number(e.target.value)) === e.target.value
                        ? Number(e.target.value)
                        : e.target.value
            })
        }
    }

    const saveChange = async () => {
        await setDoc(
            doc(
                db, "series", serie, "weekends", weekend, "sessions", thisSession.id
            ),
            thisSession,
            {merge:true}
        )
        return history.push(`/admin/sessions/list/${serie}/${weekend}`)
    }

    return thisSession ? (
        <div className="EditLine">
            <form>
                <input
                    type="text"
                    name="id"
                    className="EditInputText"
                    value={thisSession.id}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;<i>id</i>
                <br/>
                <input
                    type="text"
                    name="long"
                    className="EditInputText"
                    value={thisSession.long}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;<i>long name</i>
                <br/>
                <input
                    type="text"
                    name="short"
                    className="EditInputText"
                    value={thisSession.short}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;<i>short name</i>
                <br/>
                <input
                    type="button"
                    className="EditInputButton"
                    value="opslaan"
                    onClick={() => saveChange()}
                />
                &nbsp;
                <input
                    type="button"
                    className="EditInputButton"
                    value="herstel"
                    onClick={() => setthisSession(null)}
                />
                &nbsp;
                <input
                    type="button"
                    className="EditInputButton"
                    value="lijst"
                    onClick={() =>
                        history.push(`/admin/sessions/list/${serie}/${weekend}`)
                    }
                />
            </form>
        </div>
    ) : (
        <div className="EditLine">array not found</div>
    )
}

export default Sessions
