import React from "react"
import "./Home.css"

const WeatherAPI = () => {
    return (
        <div className="WeatherAPI">
            <a href="https://www.weatherapi.com/" title="Free Weather API" target="_new">
                <img src='//cdn.weatherapi.com/v4/images/weatherapi_logo.png' alt="Weather data by WeatherAPI.com"/>
            </a>
        </div>
    )
}
export default WeatherAPI