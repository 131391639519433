import React, {Fragment, useState, useContext, useEffect} from "react"
import moment from "moment-timezone"

import {Link, useLocation} from "react-router-dom"

import "./Header.css"
import Flag from "react-world-flags"

import {au, googleProvider} from "../utils/Firebase"

import {signInWithPopup} from 'firebase/auth'

import {MyTimeContext} from "../utils/helpers/MyTime"
import {LocalTimezoneContext} from "../utils/helpers/LocalTimezone"
import {ShowGraphsContext} from "../utils/helpers/ShowGraphs"
import {UserContext} from "../utils/helpers/User"

import {Content} from "../utils/UIFunctions"

const Header = () => {
    const [showPicker, setShowPicker] = useState(false)
    const [pickerList, setPickerList] = useState([])

    const [{myTime}, dispatchMyTime] = useContext(MyTimeContext)
    const [{localTimezone}, dispatchLocalTimezone] = useContext(
        LocalTimezoneContext
    )
    const [{showGraphs}, dispatchShowGraphs] = useContext(ShowGraphsContext)

    const [thisIsAdmin] = useState(
        useLocation().pathname.split("/")[1] === "admin"
    )

    const [{user}, dispatchUser] = useContext(UserContext)
    const [content, setContent] = useState(null)

    useEffect(() => {
        Content(document.location.hostname, "Header").then((newContent) => {
            setContent(newContent)
        })
    }, [])

    useEffect(() => {
        if (thisIsAdmin) {
            au.onAuthStateChanged((user) => {
                if (user) {
                    dispatchUser({type:"LOGIN", user:user})
                }
            })
        }
    }, [dispatchUser, thisIsAdmin])

    const signInWithGoogle = async () => {
        let result = await signInWithPopup(au,googleProvider)
        if (result.user.email === "ziggyziggyziggy@gmail.com") {
            dispatchUser({type:"LOGIN", user:result.user})
        } else {
            dispatchUser({type:"LOGOUT"})
        }

    }

    function searchTimezone(e) {
        let value = e.target.value
        if (value.length > 0) {
            let zoneslist = moment.tz.names()
            let pickerlist = []
            for (let zone in zoneslist) {
                if (
                    zoneslist[zone].toLowerCase().includes(value.toLowerCase()) &&
                    pickerlist.length < 20
                ) {
                    pickerlist.push(zoneslist[zone])
                }
            }
            setPickerList(pickerlist)
        }
        return true
    }

    const setTimezone = (zone) => {
        dispatchLocalTimezone({type:"SET", localTimezone:zone})
        setShowPicker(false)
        return true
    }

    const toggleMyTime = () => {
        dispatchMyTime({type:"TOGGLE"})
    }

    return (
        <div className="header">
            {showPicker ? (
                <div className="headerbuttonbar">
                    {content &&
                        content.length > 0 &&
                        content.find((o) => o.tag === "enterCity").content}
                    :&nbsp;
                    <input type="text" onChange={(e) => searchTimezone(e)}/>{" "}
                    <span
                        className="headerbutton"
                        onClick={() => setTimezone(moment.tz.guess())}
                    >
            {content &&
                content.length > 0 &&
                content.find((o) => o.tag === "detect").content}
          </span>
                    <div
                        className="headerbutton"
                        onClick={() => setShowPicker(false)}
                        title={
                            content &&
                            content.length > 0 &&
                            content.find((o) => o.tag === "cancelEditTimezone").content
                        }
                    >
                        <i className="far fa-clock"/>
                    </div>
                    <div className="pickerlist">
                        {pickerList.map((zone) => {
                            return (
                                <p
                                    className="pickerlistitem"
                                    key={zone}
                                    onClick={() => setTimezone(zone)}
                                >
                                    {zone.replace("_", " ")}
                                </p>
                            )
                        })}
                    </div>
                </div>
            ) : (
                <Fragment>
                    <div className="headerbuttonbar">
                        {/*{user ?*/}
                        {/*    : }*/}
                        {thisIsAdmin && user && (
                            <Fragment>
                                <div
                                    onClick={() => dispatchUser({type:"LOGOUT"})}
                                    className="headerbutton"
                                    title={
                                        content &&
                                        content.length > 0 &&
                                        content.find((o) => o.tag === "logoutTitle").content
                                    }
                                >
                                    <i className="fas fa-user"/>
                                    &nbsp;{user.displayName}
                                </div>
                                <div className="headerbutton">
                                    <Link to="/admin/locations/list">
                                        <i className="fas fa-globe"/>
                                    </Link>
                                </div>
                                <div className="headerbutton">
                                    <Link to="/admin/series/list">
                                        <i className="fas fa-th-list"/>
                                    </Link>
                                </div>
                            </Fragment>
                        )}
                        {thisIsAdmin && !user && (
                            <div
                                onClick={() => signInWithGoogle()}
                                className="headerbutton"
                                title="Log in"
                            >
                                <i className="fab fa-google"/>
                            </div>
                        )}
                        {!thisIsAdmin && myTime && (
                            <div
                                onClick={() => toggleMyTime()}
                                className="headerbutton"
                                title={
                                    content &&
                                    content.length > 0 &&
                                    content.find((o) => o.tag === "toggleMyTimeOff").content
                                }
                            >
                                {content &&
                                    content.length > 0 &&
                                    content.find((o) => o.tag === "myTime").content}
                                &nbsp;
                                <i className="fas fa-home"/>
                            </div>
                        )}
                        {!thisIsAdmin && !myTime && (
                            <div
                                onClick={() => toggleMyTime()}
                                className="headerbutton"
                                title={`${
                                    content &&
                                    content.length > 0 &&
                                    content.find((o) => o.tag === "toggleMyTimeOn").content
                                }
 (${localTimezone && localTimezone.replace("_", " ")})`}
                            >
                                {content &&
                                    content.length > 0 &&
                                    content.find((o) => o.tag === "circuitTime").content}
                                &nbsp;
                                <i className="fas fa-globe-americas"/>
                            </div>
                        )}
                        {!thisIsAdmin && (
                            <div
                                className="headerbutton"
                                onClick={() => setShowPicker(true)}
                                title={`${
                                    content &&
                                    content.length > 0 &&
                                    content.find((o) => o.tag === "editTimezoneTitle").content
                                } ${localTimezone && localTimezone.replace("_", " ")})`}
                            >
                                <i className="far fa-clock"/>
                            </div>
                        )}
                        {!thisIsAdmin && (
                            <div
                                className="headerbutton"
                                onClick={() => dispatchShowGraphs({type:"TOGGLE"})}
                                title={
                                    content &&
                                    content.length > 0 &&
                                    content.find((o) => o.tag === "toggleShowGraphsTitle").content
                                }
                            >
                                {showGraphs ? (
                                    <Fragment>
                                        {content &&
                                            content.length > 0 &&
                                            content.find((o) => o.tag === "charts").content}
                                        &nbsp;
                                        <i className="fas fa-chart-bar"/>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {content &&
                                            content.length > 0 &&
                                            content.find((o) => o.tag === "text").content}
                                        &nbsp;
                                        <i className="fas fa-list"/>
                                    </Fragment>
                                )}
                            </div>
                        )}
                        {!thisIsAdmin && (
                            <div className="headerbutton">
                                {document.location.host === "regenrace.nl" ? (
                                    <Flag
                                        code="nl"
                                        height="14"
                                        style={{marginTop:"2px", marginBottom:"-2px"}}
                                        onClick={() =>
                                            (document.location.href = `https://fullwet.com${window.location.hash}`)
                                        }
                                        title="huidige taal Nederlands, klik om te wisselen"
                                    />
                                ) : (
                                    <Flag
                                        code="gb"
                                        height="14"
                                        style={{marginTop:"2px", marginBottom:"-2px"}}
                                        onClick={() =>
                                            (document.location.href = `https://regenrace.nl${window.location.hash}`)
                                        }
                                        title="current language English, click to toggle"
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </Fragment>
            )}
            }
        </div>
    )
}

export default Header
