import React, { createContext, useReducer } from "react";

let ShowMinutelyReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE":
      return { showMinutely: !state.showMinutely };
    case "SET":
      return { showMinutely: action.showMinutely };
    default:
      return state;
  }
};
const initialShowMinutely = { showMinutely: true };
const ShowMinutelyContext = createContext(initialShowMinutely);
const ShowMinutelyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    ShowMinutelyReducer,
    initialShowMinutely
  );

  return (
    <ShowMinutelyContext.Provider value={[state, dispatch]}>
      {children}
    </ShowMinutelyContext.Provider>
  );
};

export { ShowMinutelyContext, ShowMinutelyProvider };