import React from "react";

import { BrowserRouter, Switch, Route } from "react-router-dom";

import { UserProvider } from "./utils/helpers/User";

import Home from "./Home/Home";
import Admin from "./Admin/Admin";
import { WeekendProvider } from "./utils/helpers/Weekend";
// import {auth, providers} from "./utils/Firebase"
// import Detail from './Detail/Detail'
import About from "./About/About";
import {WeatherServiceProvider} from "./utils/helpers/WeatherService"
// import Calendar from './Calendar/Calendar'

const App = () => {
  // const [{user}, dispatchUser] = useContext(UserContext)

  return (
    <UserProvider>
      <WeatherServiceProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/admin">
            <Admin />
          </Route>
          <Route path="/">
            <WeekendProvider>
              <Home />
            </WeekendProvider>
          </Route>
          {/*<Route exact path="/series">*/}
          {/*    <Home/>*/}
          {/*</Route>*/}
          {/*<Route path="/series/:seriesId">*/}
          {/*    <Detail/>*/}
          {/*</Route>*/}
          {/*<Route path="/calendar/:seriesId?">*/}
          {/*    <Calendar/>*/}
          {/*</Route>*/}
        </Switch>
      </BrowserRouter>
      </WeatherServiceProvider>
    </UserProvider>
  );
};

export default App;
