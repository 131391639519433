import React, {createContext, useReducer} from "react"

let WeatherServiceReducer = (state, action) => {
    switch (action.type) {
        case "TOGGLE":
            // localStorage.setItem("weatherService", state.weatherService === "OW" ? "DS" : "OW")
            // return {weatherService:state.weatherService === "OW" ? "DS" : "OW"}
        return {weatherService:"WA"}
        case "SET":
            // return {weatherService:action.weatherService}
        return {weatherService:"WA"}
        default:
            return state
    }
}
// const initialWeatherService = {weatherService: localStorage.getItem("weatherService") || "OW"};
const initialWeatherService = {weatherService:"WA"}
const WeatherServiceContext = createContext(initialWeatherService)
const WeatherServiceProvider = ({children}) => {
    const [state, dispatch] = useReducer(WeatherServiceReducer, initialWeatherService)

    return (
        <WeatherServiceContext.Provider value={[state, dispatch]}>
            {children}
        </WeatherServiceContext.Provider>
    )
}

export {WeatherServiceContext, WeatherServiceProvider}