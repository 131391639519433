import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"

import React from "react"
import {createRoot} from "react-dom/client"
import App from "./App"

import "normalize.css"

require("./index.css")

const container = document.getElementById("root")
const root = createRoot(container)
root.render(<App/>)

