import {initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from "firebase/storage"
import {getAuth, GoogleAuthProvider} from "firebase/auth"
import {getFunctions, httpsCallable} from "firebase/functions"
import {getAnalytics} from "firebase/analytics"

const app = initializeApp(require(`./FirebaseConfig.json`))

const db = getFirestore(app)
const storage = getStorage(app)

const ga =
    window.location.hash !== "#assen" &&
    !window.location.pathname.includes("/admin")
        ? getAnalytics(app)
        : null

const au = getAuth(app)
au.useDeviceLanguage()

const googleProvider = new GoogleAuthProvider()

const functions = getFunctions(app)
const get_hourly = httpsCallable(functions, 'get_hourly')

export {db, storage, ga, au, googleProvider, get_hourly}

// import firebase from "firebase/app";
// import "firebase/firestore";
// import "firebase/auth";
// import "firebase/analytics";
// import "firebase/storage";
// import "firebase/functions"
//
// if (!firebase.apps.length || firebase.apps.length === 0) {
//   firebase.initializeApp(require("./FirebaseConfig"));
// }
//
// // auth.setPersistence(auth.Auth.Persistence.SESSION)
//
// export const db = firebase.firestore();
//
// export const au = firebase.auth();
//
// export const providers = {
//   googleProvider: new firebase.auth.GoogleAuthProvider(),
// };
//
// export const storage = firebase.storage();
//
// export const ga =
//   window.location.hash !== "#assen" &&
//   !window.location.pathname.includes("/admin")
//     ? firebase.analytics()
//     : null;
//
// const functions=firebase.functions()
// export const get_hourly=functions.httpsCallable(functions,"get_hourly")
