import React, {Fragment,useContext } from "react";
import "./RaceCard.css";
import { LocationContext } from "../../utils/helpers/Location";
import { WeekendContext } from "../../utils/helpers/Weekend";
import Flag from "react-world-flags"


const Title = () => {
  const [{ serieData, weekendData }] = useContext(WeekendContext);
  const [{ locationData }] = useContext(LocationContext);

  return (
    <Fragment>
      <div className="TitleSerie">{serieData && serieData.long}</div>
      <div className="TitleWeekend">{weekendData && weekendData.long}</div>
      <div className="TitleLocation">
        {locationData && (
          <span
            className="TitleLocationOnClick"
            onClick={() =>
              window.open(
                `https://www.google.com/maps/@?api=1&map_action=map&q==${locationData.lat},${locationData.lon}&center=${locationData.lat},${locationData.lon}&basemap=satellite`
              )
            }
          >
              {locationData.country && <><Flag code={locationData.country} height={8}/>{' '}</>}
            {locationData.long}
          </span>
        )}
      </div>
    </Fragment>
  );
};

export default Title