import React, { useContext } from "react";
import { useRouteMatch, Route, Switch } from "react-router-dom";

import Header from "../Header/Header";
import Locations from "./Locations";
import Series from "./Series";
import Weekends from "./Weekends";
import Sessions from "./Sessions";

import { LocalTimezoneProvider } from "../utils/helpers/LocalTimezone";
import { MyTimeProvider } from "../utils/helpers/MyTime";
import { ShowGraphsProvider } from "../utils/helpers/ShowGraphs";
import { UserContext } from "../utils/helpers/User";

const Admin = () => {
  let { path } = useRouteMatch();
  const [{ user }] = useContext(UserContext);

  if (user)
    return (
      <ShowGraphsProvider>
        <LocalTimezoneProvider>
          <MyTimeProvider>
            <Switch>
              <Route path={`${path}/locations/:action?/:id?`}>
                <Locations />
              </Route>
              <Route path={`${path}/series/:action?/:id?`}>
                <Series />
              </Route>
              <Route path={`${path}/weekends/:action?/:serie?/:weekend?`}>
                <Weekends />
              </Route>
              <Route
                path={`${path}/sessions/:action?/:serie?/:weekend?/:session?`}
              >
                <Sessions />
              </Route>
              <Route path={path}>
                <Header />
                <div style={{ color: "white" }}>home</div>
              </Route>
            </Switch>
          </MyTimeProvider>
        </LocalTimezoneProvider>
      </ShowGraphsProvider>
    );
  else {
    return (
      <ShowGraphsProvider>
        <LocalTimezoneProvider>
          <MyTimeProvider>
            <Switch>
              <Route path="/admin">
                <Header />
              </Route>
            </Switch>
          </MyTimeProvider>
        </LocalTimezoneProvider>
      </ShowGraphsProvider>
    );
  }
};

export default Admin