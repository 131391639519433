import React, {Fragment, useContext } from "react";
import "./RaceCard.css";
import moment from "moment-timezone";
import { Spinner } from "../../utils/UIFunctions";

import { SessionsContext } from "../../utils/helpers/Sessions";
// import { ga } from "../../utils/Firebase";
import { WeekendContext } from "../../utils/helpers/Weekend";

const Sessions = () => {
  const [
    { sessions, currentSession, selectedSession },
    dispatchSessions,
  ] = useContext(SessionsContext);
  const [{ serieData }] = useContext(WeekendContext);

  const RaceCardSessionsMarker = ({
    session,
    selected,
    active,
    onSelectSession,
  }) => (
    <span
      className={`
                ${
                  selected
                    ? "RaceCardSessionsSelected"
                    : "RaceCardSessionsNotSelected"
                }
                ${active ? "RaceCardSessionsNow" : undefined}
            `}
      onClick={selected ? undefined : () => onSelectSession()}
      title={session.long}
    >
      {session.short}
    </span>
  );

  const changeSelectedSessions = (session) => {
    if (sessions)
      // window.location.hash !== "#assen" &&
      // ga.logEvent("select_session", {
      //   serie: serieData.id,
      //   session: currentSession.short,
      // });
    {
        sessions.map((ses, i) => {
            if (ses.short === session) {
                return dispatchSessions({
                    type:"SELECT",
                    currentSession:ses,
                    selectedSession:ses.short,
                });
            } else {
                return true;
            }
        });
    }
    return true
  };

  return (
    <div className="Sessions">
      <i
        className="fas fa-ellipsis-h"
        style={{ fontSize: "0.6em", transform: "translateY(-0.2em)" }}
      />
      &nbsp;&nbsp;
      {sessions &&
        currentSession &&
        sessions.map((session, i) => {
          if (session.end > Number(moment.tz("UTC").format("X"))) {
            return (
              <span key={`${session.start}${session.end}`}>
                <RaceCardSessionsMarker
                  session={session}
                  selected={session.short === selectedSession}
                  active={
                    session.start < Number(moment.tz("UTC").format("X")) &&
                    session.end > Number(moment.tz("UTC").format("X"))
                  }
                  onSelectSession={() => changeSelectedSessions(session.short)}
                />
                {i < sessions.length - 1 && (
                  <Fragment>
                    &nbsp;&nbsp;
                    <i className="fas fa-caret-right" />
                    &nbsp;&nbsp;
                  </Fragment>
                )}
              </span>
            );
          } else {
            return <Spinner />;
          }
        })}
      &nbsp;&nbsp;
      <i
        className="fas fa-ellipsis-h"
        style={{ fontSize: "0.6em", transform: "translateY(-0.2em)" }}
      />
    </div>
  );
};

export default Sessions