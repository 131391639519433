import React, {Fragment, useEffect, useState} from "react"

import {useParams, Link, useHistory} from "react-router-dom"
import Header from "../Header/Header"
import "./Admin.css"

import moment from "moment-timezone"
import {db} from "../utils/Firebase"
import {collection, onSnapshot, updateDoc, setDoc, doc} from "firebase/firestore"

const iso = require('iso-3166-1')

const Locations = () => {
    let {action, id} = useParams()
    const [locations, setLocations] = useState([])
    const [countries, setCountries] = useState([])

    useEffect(() => {
        setCountries(iso.all())
    }, [])

    useEffect(() => {
        const unsubscribe = onSnapshot(
            collection(
                db, "locations"
            ),
            snapshot => {

                let toReturn = []
                for (let d of snapshot.docs) {
                    toReturn.push({id:d.id, ...d.data()})
                }
                toReturn.sort((a, b) => a.long.localeCompare(b.long))
                return setLocations(toReturn)
            })
        return () => {
            unsubscribe()
        }
    }, [])

    return (
        <Fragment>
            <Header/>
            {(!action || action === "list") && (
                <LocationsList collection={locations} countries={countries}/>
            )}
            {action === "edit" && id && (
                <LocationsEdit id={id} collection={locations} countries={countries}/>
            )}
            {action === "add" && <LocationsAdd countries={countries}/>}
        </Fragment>
    )
}

const LocationsList = ({collection,countries}) => {
    return (
        <table className="ListTable">
            <thead>
            <tr>
                <td colSpan={4}>
                    <span className="AdminTitle">locations</span>{" "}
                    <Link to="/admin/locations/add">add</Link>
                </td>
            </tr>
            </thead>
            <tbody>
            {collection
                ? collection.map(i =>

                    <tr className="ListRow" key={i.id}>
                        <td className="ListLineCell">
                            <Link to={`/admin/locations/edit/${i.id}`}>
                                {i.long}
                            </Link>
                        </td>
                        <td className="ListLineCell">{i.lat}</td>
                        <td className="ListLineCell">{i.lon}</td>
                        <td className="ListLineCell">{i.timezone}</td>
                        <td className="ListLineCell">{i.country && countries.find(o=>o.alpha3===i.country).country}</td>
                    </tr>
                )
                : null}
            </tbody>
        </table>
    )
}

const LocationsEdit = ({id, collection,countries}) => {
    const zoneslist = moment.tz.names()
    const [thisLocation, setThisLocation] = useState(null)
    const history = useHistory()

    useEffect(() => {
        collection &&
        collection.length > 0 &&
        !thisLocation &&
        setThisLocation(collection.find((x) => x.id === id))
    }, [collection, id, thisLocation])

    const makeChange = (e) =>
        setThisLocation({
            ...thisLocation,
            [e.target.name]:
                String(Number(e.target.value)) === e.target.value
                    ? Number(e.target.value)
                    : e.target.value
        })


    const saveChange = async () => {
        await updateDoc(
            doc(db, "locations", thisLocation.id),
            thisLocation
        )

        return history.push("/admin/locations/list")
    }


    return thisLocation ? (
        <div className="EditLine">
            <form>
                <input
                    type="text"
                    name="long"
                    className="EditInputText"
                    value={thisLocation.long}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;<i>long name</i>
                <br/>
                <input
                    type="text"
                    name="short"
                    className="EditInputText"
                    value={thisLocation.short}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;<i>short name</i>
                <br/>
                <input
                    type="text"
                    name="lat"
                    className="EditInputText"
                    value={thisLocation.lat}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;
                <i>latitude</i>
                <br/>
                <input
                    type="text"
                    name="lon"
                    className="EditInputText"
                    value={thisLocation.lon}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;
                <i>longitude</i>
                <br/>
                <select
                    name="timezone"
                    className="EditInputText"
                    value={thisLocation.timezone}
                    onChange={(e) => makeChange(e)}
                >
                    {zoneslist.map((i) =>
                        <option key={i}>{i}</option>
                    )}
                </select>
                &nbsp;<i>timezone</i>
                <br/>
                <select
                    name="country"
                    className="EditInputText"
                    onChange={(e) => makeChange(e)}
                    value={thisLocation.country}
                    autoFocus
                >
                    <option key="null" value=""/>
                    {countries.map((i) => <option key={i.alpha3} value={i.alpha3}>
                        {i.country}
                    </option>)}
                </select>
                &nbsp;<i>country</i>
                <br/>
                <input
                    type="button"
                    className="EditInputButton"
                    value="opslaan"
                    onClick={() => saveChange()}
                />
                &nbsp;
                <input
                    type="button"
                    className="EditInputButton"
                    value="herstel"
                    onClick={() => setThisLocation(null)}
                />
                &nbsp;
                <input
                    type="button"
                    className="EditInputButton"
                    value="kaart"
                    onClick={() =>
                        window.open(
                            `http://maps.google.com/?ll=${thisLocation.lat},${thisLocation.lon}&z=12&t=k&q=${thisLocation.lat},${thisLocation.lon}`
                        )
                    }
                />
                &nbsp;
                <input
                    type="button"
                    className="EditInputButton"
                    value="lijst"
                    onClick={() => history.push("/admin/locations/list")}
                />
                &nbsp;
            </form>
        </div>
    ) : (
        <div className="EditLine">location not found</div>
    )
}

const LocationsAdd = ({countries}) => {
    const zoneslist = moment.tz.names()
    const [thisLocation, setThisLocation] = useState({
        id:"",
        short:"",
        long:"",
        lat:0,
        lon:0,
        timezone:"Europe/Amsterdam",
        country:""
    })
    const history = useHistory()

    const makeChange = (e) => {
        if (
            (e.target.name === "lat" || e.target.name === "lon") &&
            isNaN(e.target.value)
        ) {
            return true
        } else if (e.target.name === "long") {
            return setThisLocation({
                ...thisLocation,
                long:e.target.value,
                id:e.target.value
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f\u0020\u0027\u002d\u002e\u002f]/g, "")
                    .toLowerCase()
            })
        } else {
            return setThisLocation({
                ...thisLocation,
                [e.target.name]:
                    String(Number(e.target.value)) === e.target.value
                        ? Number(e.target.value)
                        : e.target.value
            })
        }
    }

    const saveChange = async () => {
        await setDoc(
            doc(
                db, "locations", thisLocation.id
            ),
            thisLocation,
            {merge:true}
        )
        return history.push("/admin/locations/list")
    }

    return thisLocation ? (
        <div className="EditLine">
            <form>
                <input
                    type="text"
                    name="id"
                    className="EditInputText"
                    value={thisLocation.id}
                    onChange={(e) => makeChange(e)}
                    disabled
                />
                &nbsp;<i>id</i>
                <br/>
                <input
                    type="text"
                    name="long"
                    className="EditInputText"
                    value={thisLocation.long}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;<i>long name</i>
                <br/>
                <input
                    type="text"
                    name="short"
                    className="EditInputText"
                    value={thisLocation.short}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;<i>short name</i>
                <br/>
                <input
                    type="text"
                    name="lat"
                    className="EditInputText"
                    value={thisLocation.lat}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;
                <i>latitude</i>
                <br/>
                <input
                    type="text"
                    name="lon"
                    className="EditInputText"
                    value={thisLocation.lon}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;
                <i>longitude</i>
                <br/>
                <select
                    name="timezone"
                    className="EditInputText"
                    value={thisLocation.timezone}
                    onChange={(e) => makeChange(e)}
                >
                    {zoneslist.map((i) =>
                        <option key={i}>{i}</option>
                    )}
                </select>
                &nbsp;<i>timezone</i>
                <br/>
                <select
                    name="country"
                    className="EditInputText"
                    onChange={(e) => makeChange(e)}
                >
                    <option key="null" value=""/>
                    {countries.map((i) => <option key={i.alpha3} value={i.alpha3}>
                        {i.country}
                    </option>)}
                </select>
                &nbsp;<i>country</i>
                <br/>
                <input
                    type="button"
                    className="EditInputButton"
                    value="opslaan"
                    onClick={() => saveChange()}
                />
                &nbsp;
                <input
                    type="button"
                    className="EditInputButton"
                    value="herstel"
                    onClick={() => setThisLocation(null)}
                />
                &nbsp;
                <input
                    type="button"
                    className="EditInputButton"
                    value="kaart"
                    onClick={() =>
                        window.open(
                            `http://maps.google.com/?ll=${thisLocation.lat},${thisLocation.lon}&z=12&t=k&q=${thisLocation.lat},${thisLocation.lon}`
                        )
                    }
                />
                &nbsp;
                <input
                    type="button"
                    className="EditInputButton"
                    value="lijst"
                    onClick={() => history.push("/admin/locations/list")}
                />
                &nbsp;
            </form>
        </div>
    ) : (
        <div className="EditLine">location not found</div>
    )
}

export default Locations