import React from "react";
import {ShowGraphsProvider} from "./ShowGraphs";
import {LocalTimezoneProvider} from "./LocalTimezone";
import {MyTimeProvider} from "./MyTime";
import {LocationProvider} from "./Location";
import {WeatherProvider} from "./Weather";
import {SessionsProvider} from "./Sessions";
import {WeekendProvider} from "./Weekend";
import {ShowMinutelyProvider} from "./ShowMinutely";

export const HomeProviders = ({children}) => (
    <ShowGraphsProvider>
        <LocalTimezoneProvider>
            <MyTimeProvider>
                    {children}
            </MyTimeProvider>
        </LocalTimezoneProvider>
    </ShowGraphsProvider>
);

export const RaceCardProviders = ({children}) => (
    <WeekendProvider>
        <LocationProvider>
            <WeatherProvider>
                <SessionsProvider>
                    <ShowMinutelyProvider>{children}</ShowMinutelyProvider>
                </SessionsProvider>
            </WeatherProvider>
        </LocationProvider>
    </WeekendProvider>
);