import React,{Fragment} from "react";
import "./Home.css";
import { Link } from "react-router-dom";

const Title = () => {
  return (
    <div className="Title">
      {document.location.host === "regenrace.nl" ? (
        <Fragment>
          regenrace.nl&nbsp;
          <span style={{ fontSize: "0.5em" }}>
            {require("../../package.json").version}
          </span>
        </Fragment>
      ) : (
        <Fragment>
          fullwet.com&nbsp;
          <span style={{ fontSize: "0.5em" }}>
            {require("../../package.json").version}
          </span>
          &nbsp;
          <Link to={`/about${window.location.hash}`}>
            <i className="fas fa-info-circle TitleInfo" />
          </Link>
        </Fragment>
      )}
    </div>
  );
};
export default Title;
