import React, { createContext, useReducer } from "react";
import moment from "moment-timezone";

let LocalTimezoneReducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return { localTimezone: action.localTimezone };
    default:
      return { state };
  }
};
const initialLocalTimezone = { localTimezone: moment.tz.guess() };
const LocalTimezoneContext = createContext(initialLocalTimezone);
const LocalTimezoneProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    LocalTimezoneReducer,
    initialLocalTimezone
  );
  return (
    <LocalTimezoneContext.Provider value={[state, dispatch]}>
      {children}
    </LocalTimezoneContext.Provider>
  );
};

export { LocalTimezoneContext, LocalTimezoneProvider };