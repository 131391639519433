import React from "react"
import moment from "moment-timezone"
import { db } from "./Firebase"

import { getDocs, collection } from 'firebase/firestore'

export const CalculateCardGrid = (devicewidth, seriesCount) => {
    let cardWidth = devicewidth < 400 ? devicewidth - 25 : 375
    let cardsPerRow = Math.floor(devicewidth / (cardWidth + 2))
    let emptyCards = cardsPerRow - (seriesCount % cardsPerRow)
    if (emptyCards % cardsPerRow === 0) {
        emptyCards = 0
    }
    let fillers = []
    for (let i = 0; i < emptyCards; i++) {
        fillers.push(
            <div
                style={{ width: `${cardWidth}px` }}
                className="EmptyCard"
                key={`filler${i}`}
            />
        )
    }
    return [cardWidth, fillers]
}

export const WeatherIcon = (iconName) => {
    let returnIcon
    switch (iconName) {
        case "1000":
        case "clear-day":
        case "01d":
            returnIcon = "fa-sun"
            break
        case "clear-night":
        case "01n":
            returnIcon = "fa-moon"
            break
        case "rain":
        case "09d":
        case "09n":
        case "10d":
        case "10n":
        case "11d":
        case "11n":
        case "1063":
        case "1150":
        case "1153":
        case "1168":
        case "1171":
        case "1180":
        case "1183":
        case "1186":
        case "1189":
        case "1192":
        case "1195":
        case "1198":
        case "1201":
        case "1240":
        case "1243":
        case "1246":
        case "1273":
        case "1276":
            returnIcon = "fa-cloud-showers-heavy"
            break
        case "snow":
        case "sleet":
        case "13d":
        case "13n":
        case "1066":
        case "1069":
        case "1072":
        case "1114":
        case "1117":
        case "1204":
        case "1207":
        case "1210":
        case "1213":
        case "1216":
        case "1219":
        case "1222":
        case "1225":
        case "1237":
        case "1249":
        case "1252":
        case "1255":
        case "1258":
        case "1261":
        case "1264":
        case "1279":
        case "1282":
            returnIcon = "fa-snowflake"
            break
        case "1087":
            returnIcon = "fa-cloud-bolt"
            break
        case "wind":
            returnIcon = "fa-wind"
            break
        case "fog":
        case "50d":
        case "50n":
        case "1030":
        case "1135":
        case "1147":
            returnIcon = "fa-smog"
            break
        case "cloudy":
        case "03d":
        case "03n":
        case "04d":
        case "04n":
        case "1006":
        case "1009":
            returnIcon = "fa-cloud"
            break
        case "partly-cloudy-day":
        case "02d":
        case "1003":
            returnIcon = "fa-cloud-sun"
            break
        case "partly-cloudy-night":
        case "02n":
            returnIcon = "fa-cloud-moon"
            break
        default:
            returnIcon = "fa-question-circle"
    }
    return returnIcon
}

export const Spinner = () => (
    <svg
        width="25px"
        height="25px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        className="lds-eclipse"
        style={{ verticalAlign: "middle" }}
    >
        <path
            stroke="none"
            d="M10 50A40 40 0 0 0 90 50A40 45 0 0 1 10 50"
            fill="lightskyblue"
            transform="rotate(40.2973 50 52.5)"
        >
            <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 52.5;360 50 52.5"
                keyTimes="0;1"
                dur="1s"
                begin="0s"
                repeatCount="indefinite"
            />
        </path>
    </svg>
)

export const ParseWeatherForHourChart = (
    predictions,
    timezone,
    intensityThreshold,
    probabilityThreshold
) => {
    let graphBlocks = []
    for (let i in predictions) {
        let rain = false
        if (
            predictions[i].intensity >= intensityThreshold &&
            predictions[i].probability >= probabilityThreshold
        ) {
            rain = true
            let color = "#FFFFFF"
            if (predictions[i].intensity < 5) {
                let hex = Math.floor(
                    (5 - Number(predictions[i].intensity) * 0.5) * 25.5
                )
                color = `#${hex.toString(16)}${hex.toString(16)}FF`
            } else {
                color = "#0000FF"
            }
            let opacity = predictions[i].probability * 0.75 + 0.25
            graphBlocks.push({
                time: `${moment
                    .unix(predictions[i].time)
                    .tz(timezone)
                    .format("HH")}-${moment
                        .unix(predictions[i].time + 3600)
                        .tz(timezone)
                        .format("HH")}`,
                intensity: predictions[i].intensity,
                probability: `${Math.round(predictions[i].probability * 100)}%`,
                color: color,
                opacity: opacity
            })
        }
        if (!rain) {
            graphBlocks.push({
                time: `${moment
                    .unix(predictions[i].time)
                    .tz(timezone)
                    .format("HH")}-${moment
                        .unix(predictions[i].time + 3600)
                        .tz(timezone)
                        .format("HH")}`,
                intensity: 0,
                probability: "0%",
                color: "#FFFFFF",
                opacity: 1
            })
        }
    }
    return graphBlocks
}

export const ParseWeatherForVictoryHourly = (
    predictions,
    timezone,
    intensityThreshold,
    probabilityThreshold
) => {
    let graphBlocks = []
    for (let i in predictions) {
        let rain = false
        if (
            predictions[i].intensity >= intensityThreshold &&
            predictions[i].probability >= probabilityThreshold
        ) {
            rain = true
            let color = "#FFFFFF"
            if (predictions[i].intensity < 5) {
                let hex = Math.floor(
                    (5 - Number(predictions[i].intensity) * 0.5) * 25.5
                )
                color = `#${hex.toString(16)}${hex.toString(16)}FF`
            } else {
                color = "#0000FF"
            }
            let opacity = predictions[i].probability * 0.75 + 0.25
            graphBlocks.push({
                time: `${moment
                    .unix(predictions[i].time)
                    .tz(timezone)
                    .format("HH")}-${moment
                        .unix(predictions[i].time + 3600)
                        .tz(timezone)
                        .format("HH")}`,
                intensity: predictions[i].intensity
            })
        }
        if (!rain) {
            graphBlocks.push({
                time: `${moment
                    .unix(predictions[i].time)
                    .tz(timezone)
                    .format("HH")}-${moment
                        .unix(predictions[i].time + 3600)
                        .tz(timezone)
                        .format("HH")}`,
                intensity: 0
            })
        }
    }
    return graphBlocks
}

export const ParseWeatherForMinuteChart = (
    predictions,
    timezone,
    intensityThreshold,
    probabilityThreshold
) => {
    let graphBlocks = []
    for (let i in predictions) {
        let rain = false
        if (
            predictions[i].intensity >= intensityThreshold &&
            predictions[i].probability >= probabilityThreshold
        ) {
            rain = true
            let color = "#FFFFFF"
            if (predictions[i].intensity < 5) {
                let hex = Math.floor(
                    (5 - Number(predictions[i].intensity) * 0.5) * 25.5
                )
                color = `#${hex.toString(16)}${hex.toString(16)}FF`
            } else {
                color = "#0000FF"
            }
            let opacity = predictions[i].probability * 0.75 + 0.25
            graphBlocks.push({
                time: moment.unix(predictions[i].time).tz(timezone).format("HH:mm"),
                intensity: predictions[i].intensity,
                probability: `${Math.round(predictions[i].probability * 100)}%`,
                color: color,
                opacity: opacity
            })
        }
        if (!rain) {
            graphBlocks.push({
                time: moment.unix(predictions[i].time).tz(timezone).format("HH:mm"),
                intensity: 0,
                probability: "0%",
                color: "#FFFFFF",
                opacity: 1
            })
        }
    }
    return graphBlocks
}

export const Content = (site, page) => {
    return new Promise(async (resolve, reject) => {
        try {
            let snapshot = await getDocs(collection(db, "sites", site === "localhost" ? "regenrace.nl" : site, page))
            let toReturn = []
            if (!snapshot.empty) {
                for (let doc of snapshot.docs) {
                    toReturn.push({ tag: doc.id, content: doc.data().content })
                }
            }
            resolve(toReturn)
        } catch
        (error) {
            reject({ error: "error loading content", details: error.message })
        }
    })
}