import React, {Fragment, useEffect, useState} from "react"

import {useParams, Link, useHistory} from "react-router-dom"
import Header from "../Header/Header"
import "./Admin.css"

import {db} from "../utils/Firebase"
import {collection, onSnapshot, updateDoc, doc, setDoc} from "firebase/firestore"

const Weekends = () => {
    let {action, serie, weekend} = useParams()
    const [weekends, setWeekends] = useState([])
    const [locations, setLocations] = useState([])

    useEffect(() => {
        const unsubscribe = onSnapshot(
            collection(
                db, "locations"
            ),
            snapshot => {
                if (!snapshot.empty) {
                    let toReturn = []
                    for (let d of snapshot.docs) {
                        toReturn.push({id:d.id, ...d.data()})
                    }
                    toReturn.sort((a, b) => a.long.localeCompare(b.long))
                    return setLocations(toReturn)
                }
            })
        return () => {
            unsubscribe()
        }
    }, [])

    useEffect(() => {
        const unsubscribe = onSnapshot(
            collection(
                db, "series", serie, "weekends"
            ),
            snapshot => {
                let toReturn = []
                if (!snapshot.empty) {
                    for (let d of snapshot.docs) {
                        toReturn.push({id:d.id, ...d.data()})
                    }
                }
                return setWeekends(toReturn)
            })
        return () => {
            unsubscribe()
        }
    }, [serie])

    return (
        <Fragment>
            <Header/>
            {(!action || action === "list") && (
                <WeekendsList
                    collection={weekends}
                    serie={serie}
                    locations={locations}
                />
            )}
            {action === "edit" && serie && weekend && (
                <WeekendsEdit
                    serie={serie}
                    weekend={weekend}
                    collection={weekends}
                    locations={locations}
                />
            )}
            {action === "add" && serie && (
                <WeekendsAdd serie={serie} locations={locations}/>
            )}
        </Fragment>
    )
}

const WeekendsList = ({collection, serie, locations}) =>
    <table className="ListTable">
        <thead>
        <tr>
            <td colSpan={4}>
                <span className="AdminTitle">weekends for {serie}</span>{" "}
                <Link to={`/admin/weekends/add/${serie}`}>add</Link>
            </td>
        </tr>
        </thead>
        <tbody>
        {collection
            ? Object.keys(collection).map((i) =>
                <tr className="ListRow" key={i}>
                    <td className="ListLineCell">
                        <Link
                            to={`/admin/weekends/edit/${serie}/${collection[i].id}`}
                        >
                            {collection[i].long}
                        </Link>
                    </td>
                    <td className="ListLineCell">{collection[i].short}</td>
                    <td className="ListLineCell">
                        {
                            locations.find((el) => el.id === collection[i].location)
                                .long
                        }
                    </td>
                    <td className="ListLineCell">
                        <Link
                            to={`/admin/sessions/list/${serie}/${collection[i].id}`}
                        >
                            <i className="far fa-clock"/>
                        </Link>
                    </td>
                </tr>)
            : null}
        </tbody>
    </table>

const WeekendsEdit = ({serie, weekend, collection, locations}) => {
    const [thisWeekend, setThisWeekend] = useState(null)
    const history = useHistory()

    useEffect(() => {
        collection &&
        collection.length > 0 &&
        !thisWeekend &&
        setThisWeekend(collection.find((x) => x.id === weekend))
    }, [collection, weekend, thisWeekend])

    const makeChange = (e) =>
        setThisWeekend({
            ...thisWeekend,
            [e.target.name]:
                String(Number(e.target.value)) === e.target.value
                    ? Number(e.target.value)
                    : e.target.value
        })

    const saveChange = async () => {
        await updateDoc(
            doc(
                db, "series", serie, "weekends", weekend
            ),
            thisWeekend
        )
        return history.push(`/admin/weekends/list/${serie}`)
    }

    return thisWeekend ? (
        <div className="EditLine">
            <form>
                <input
                    type="text"
                    name="long"
                    className="EditInputText"
                    value={thisWeekend.long}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;<i>long name</i>
                <br/>
                <input
                    type="text"
                    name="short"
                    className="EditInputText"
                    value={thisWeekend.short}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;<i>short name</i>
                <br/>
                <select
                    name="location"
                    className="EditInputText"
                    value={thisWeekend.location}
                    onChange={(e) => makeChange(e)}
                >
                    {locations.map((i) => <option key={i.id} value={i.id}>
                        {i.long}
                    </option>)}
                </select>
                &nbsp;<i>location</i>
                <br/>
                <input
                    type="button"
                    className="EditInputButton"
                    value="opslaan"
                    onClick={() => saveChange()}
                />
                &nbsp;
                <input
                    type="button"
                    className="EditInputButton"
                    value="herstel"
                    onClick={() => setThisWeekend(null)}
                />
                &nbsp;
                <input
                    type="button"
                    className="EditInputButton"
                    value="lijst"
                    onClick={() => history.push(`/admin/weekends/list/${serie}`)}
                />
            </form>
        </div>
    ) : (
        <div className="EditLine">serie not found</div>
    )
}

const WeekendsAdd = ({serie, locations}) => {
    const [thisWeekend, setThisWeekend] = useState({
        id:"",
        location:"",
        long:"",
        short:""
    })
    const history = useHistory()

    const makeChange = (e) => {
        if (e.target.name === "id") {
            return setThisWeekend({
                ...thisWeekend,
                id:e.target.value
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f\u0020\u0027\u002d\u002e\u002f]/g, "")
                    .toLowerCase()
            })
        } else {
            return setThisWeekend({
                ...thisWeekend,
                [e.target.name]:
                    String(Number(e.target.value)) === e.target.value
                        ? Number(e.target.value)
                        : e.target.value
            })
        }
    }

    const saveChange = async () => {
        await setDoc(
            doc(
                db, "series", serie, "weekends", thisWeekend.id
            ),
            thisWeekend,
            {merge:true}
        )

        return history.push(`/admin/weekends/list/${serie}`)
    }

    return thisWeekend ? (
        <div className="EditLine">
            <form>
                <input
                    type="text"
                    name="id"
                    className="EditInputText"
                    value={thisWeekend.id}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;<i>id</i>
                <br/>
                <input
                    type="text"
                    name="long"
                    className="EditInputText"
                    value={thisWeekend.long}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;<i>long name</i>
                <br/>
                <input
                    type="text"
                    name="short"
                    className="EditInputText"
                    value={thisWeekend.short}
                    onChange={(e) => makeChange(e)}
                />
                &nbsp;<i>short name</i>
                <br/>
                <select
                    name="location"
                    className="EditInputText"
                    onChange={(e) => makeChange(e)}
                >
                    <option key="null" value=""/>
                    {locations.map((i) => <option key={i.id} value={i.id}>
                        {i.long}
                    </option>)}
                </select>
                &nbsp;<i>location</i>
                <br/>
                <input
                    type="button"
                    className="EditInputButton"
                    value="opslaan"
                    onClick={() => saveChange()}
                />
                &nbsp;
                <input
                    type="button"
                    className="EditInputButton"
                    value="herstel"
                    onClick={() => setThisWeekend(null)}
                />
                &nbsp;
                <input
                    type="button"
                    className="EditInputButton"
                    value="lijst"
                    onClick={() => history.push(`/admin/weekends/list/${serie}`)}
                />
            </form>
        </div>
    ) : (
        <div className="EditLine">array not found</div>
    )
}

export default Weekends
