import React, { useState, useEffect } from "react"

import "./Home.css"

import RaceCard from "./RaceCard/RaceCard"
import Header from "../Header/Header"

import { CalculateCardGrid } from "../utils/UIFunctions"

import { db } from "../utils/Firebase"
import { collection, onSnapshot } from 'firebase/firestore'

import { HomeProviders, RaceCardProviders } from "../utils/helpers/Providers"
import Title from "./Title"

import WeatherAPI from "./WeatherAPI"

const Home = () => {
    const [deviceWidth, setDeviceWidth] = useState(null)
    const [cardWidth, setCardWidth] = useState(0)
    const [fillers, setFillers] = useState(0)
    const [weekends, setWeekends] = useState([])

    useEffect(() => {
        setDeviceWidth(
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth
        )


        const unsubscribe = onSnapshot(collection(db, "series"), snapshot => {
            if (!snapshot.empty) {
                let weekends = []
                for (let doc of snapshot.docs) {
                    if (doc.data().currentWeekend !== null) {
                        if ((doc.id !== "assen" && doc.id !== "test") || ((doc.id === "assen" || doc.id === "test") && window.location.hash === "#assen")) {
                            weekends.push({
                                serie: doc.id,
                                weekend: doc.data().currentWeekend,
                                weekendOrder: (doc.id === "test" && -2) || (doc.id === "assen" && -1) || doc.data().weekendOrder
                            })
                        }
                    }
                }
                weekends.sort((a, b) =>
                    a.weekendOrder > b.weekendOrder
                        ? 1
                        : b.weekendOrder > a.weekendOrder
                            ? -1
                            : 0
                )
                return setWeekends(weekends)
            }
        })
        return () => {
            unsubscribe()
        }
    }, []
    )


    useEffect(() => {
        if (deviceWidth && weekends) {
            let [a, b] = CalculateCardGrid(deviceWidth, weekends.length)
            setCardWidth(a)
            setFillers(b)
        }
    }, [deviceWidth, weekends])

    useEffect(() => {
        window.addEventListener("resize", () =>
            setDeviceWidth(
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth
            )
        )
        return window.removeEventListener("resize", null)
    }, [])

    return (
        <HomeProviders>
            <div className="Home">
                <Header />
                {weekends &&
                    weekends.map((i) =>
                        <RaceCardProviders key={i.serie}>
                            <RaceCard
                                cardWidth={cardWidth}
                                seriesId={i.serie}
                                weekendId={i.weekend}
                            />
                        </RaceCardProviders>
                    )
                }
                {fillers.length > 0 && fillers.map((i) => i)}

                <WeatherAPI />
                <Title />
            </div>
        </HomeProviders>
    )
}


export default Home
