import React, { createContext, useReducer } from "react";

let SessionsReducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return { ...state, sessions: action.sessions };
    case "SELECT":
      return {
        ...state,
        currentSession: action.currentSession,
        selectedSession: action.selectedSession,
      };
    case "SET_WEATHER":
      return { ...state, sessionWeather: action.sessionWeather };
    default:
      return state;
  }
};
const initialSessions = {
  sessions: [],
  currentSession: [],
  sessionWeather: [],
  selectedSession: null,
};
const SessionsContext = createContext(initialSessions);
const SessionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SessionsReducer, initialSessions);
  return (
    <SessionsContext.Provider value={[state, dispatch]}>
      {children}
    </SessionsContext.Provider>
  );
};

export { SessionsContext, SessionsProvider };
