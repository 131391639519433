import React, { createContext, useReducer } from "react";

let MyTimeReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE":
      return { myTime: !state.myTime };
    case "SET":
      return { myTime: action.myTime };
    default:
      return state;
  }
};
const initialMyTime = { myTime: true };
const MyTimeContext = createContext(initialMyTime);
const MyTimeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MyTimeReducer, initialMyTime);

  return (
    <MyTimeContext.Provider value={[state, dispatch]}>
      {children}
    </MyTimeContext.Provider>
  );
};

export { MyTimeContext, MyTimeProvider };