import React, {useState, useEffect, useContext} from "react"

import "./RaceCard.css"

import Title from "./Title"
import Predictions from "./Predictions"
import Sessions from "./Sessions"
import UpdateTime from "./UpdateTime"
import CurrentWeather from "./CurrentWeather"

import moment from "moment-timezone"

import {db, storage} from "../../utils/Firebase"

import {collection, doc, getDoc, onSnapshot, query, where, orderBy} from "firebase/firestore"

import {LocationContext} from "../../utils/helpers/Location"
import {WeekendContext} from "../../utils/helpers/Weekend"
import {SessionsContext} from "../../utils/helpers/Sessions"
import {WeatherContext} from "../../utils/helpers/Weather"
import {RoundTimes} from "../../utils/DataFunctions"
import {WeatherServiceContext} from "../../utils/helpers/WeatherService"
import {getDownloadURL, ref} from "firebase/storage"

const RaceCard = ({cardWidth, seriesId, weekendId}) => {
    const [{serieData, weekendData}, dispatchWeekend] = useContext(
        WeekendContext
    )
    const [{currentSession}, dispatchSessions] = useContext(SessionsContext)
    const [, dispatchWeather] = useContext(WeatherContext)
    const [{locationData}, dispatchLocationData] = useContext(LocationContext)
    const [{weatherService}] = useContext(WeatherServiceContext)

    // LOAD SERIES AND WEEKEND DATA
    useEffect(() => {
        const i = async () => {
            if (seriesId && weekendId) {
                let s = await getDoc(doc(db, "series", seriesId))
                let w = await getDoc(doc(db, "series", seriesId, "weekends", weekendId))
                dispatchWeekend({type:"SET", serie:s.data(), weekend:w.data()})
            }
            return true
        }
        i()
    }, [seriesId, weekendId, dispatchWeekend])

    // LOAD SESSIONS DATA
    useEffect(() => {
        if (serieData && serieData.id && weekendData && weekendData.id) {
            const unsubscribe =
                onSnapshot(
                    query(collection(db, "series", serieData.id, "weekends", weekendData.id, "sessions"),
                        where("end", ">", Number(moment.tz("UTC").format("X")) + 60),
                        orderBy("end")
                    )
                    , snapshot => {
                        let results = []
                        if (!snapshot.empty) {
                            for (let d of snapshot.docs) {
                                if (d.data().end > Number(moment.tz("UTC").format("X")) + 60) {
                                    results.push(d.data())
                                }
                            }
                        }
                        dispatchSessions({type:"SET", sessions:results})
                        dispatchSessions({
                            type:"SELECT",
                            currentSession:results[0],
                            selectedSession:results[0].short
                        })
                    })
            return () => {
                unsubscribe()
            }
        }
    }, [serieData, weekendData, dispatchSessions])

    // LOAD LOCATION DATA
    useEffect(() => {
        if (weekendData && weekendData.location) {
            const unsubscribe =
                onSnapshot(doc(
                        db, "locations", weekendData.location
                    ), d =>
                        dispatchLocationData({type:"SET", locationData:d.data()})
                )
            return () => unsubscribe()
        }
    }, [weekendData, dispatchLocationData])

    // LOAD HOURLY WEATHER DATA
    useEffect(() => {
        let hourlyCollection = "WAhourly"
        if (locationData && locationData.id) {
            const unsubscribe =
                onSnapshot(
                    collection(
                        db, "locations", locationData.id, hourlyCollection
                    ),
                    orderBy("time"),
                    snapshot => {
                        let hourly = []
                        if (!snapshot.empty) {
                            for (let d of snapshot.docs) {
                                hourly.push(d.data())
                            }
                        }
                        dispatchWeather({type:"SET_HOURLY", hourlyWeather:hourly})
                        let [predictionStart, predictionEnd] = RoundTimes(
                            currentSession.start,
                            currentSession.end
                        )
                        let sw = []
                        for (let h of hourly) {
                            h.time >= predictionStart && h.time < predictionEnd && sw.push(h)
                        }
                        dispatchWeather({type:"SET_SESSION", sessionWeather:sw})
                    })
            return () => {
                unsubscribe()
            }
        }
    }, [locationData, dispatchWeather, currentSession, weatherService])

    // LOAD MINUTELY WEATHER DATA
    useEffect(() => {
        let minutelyCollection = weatherService === "OW" ? "OWminutely" : "DSminutely"
        if (locationData && locationData.id) {
            const unsubscribe =
                onSnapshot(
                    query(
                        collection(
                            db, "locations", locationData.id, minutelyCollection
                        ),
                        where("time", ">=", Number(moment.tz("UTC").format("X"))),
                        orderBy("time")),
                    snapshot => {
                        let minutely = []
                        if (!snapshot.empty) {
                            for (let d of snapshot.docs) {
                                minutely.push(d.data())
                                // console.log(doc.data())
                            }
                            dispatchWeather({
                                type:"SET_MINUTELY",
                                minutelyWeather:minutely
                            })
                            // setInterval(() => {
                            //         let m = []
                            //         snapshot.forEach(doc => {
                            //             if (Number(doc.data().time > Number(moment.tz("UTC").format("X")))) m.push(doc.data())
                            //         })
                            //         console.log(m.length)
                            //         if (Math.max.apply(Math, m.map(o => o.time)) === Math.max.apply(Math, minutely.map(o => o.time))) {
                            //             dispatchWeather({type: 'SET_MINUTELY', minutelyWeather: m})
                            //         } else {
                            //             dispatchWeather({type: 'SET_MINUTELY', minutelyWeather: minutely})
                            //         }
                            //     },
                            //     15000)
                        }
                    })
            return () => {
                unsubscribe()
            }
        }
    }, [locationData, dispatchWeather, currentSession, weatherService])

    return <RaceCardUI cardWidth={cardWidth}/>
}

const RaceCardUI = ({cardWidth}) => {
    const [backgroundImage, setBackgroundImage] = useState(null)
    const [{serieData}] = useContext(WeekendContext)

    useEffect(() => {
        const i = async () =>
            serieData &&
            serieData.id &&
            setBackgroundImage(await getDownloadURL(ref(storage, `images/${serieData.id}.jpg`)))
        i()
    }, [serieData])

    return serieData && serieData.id ? (
        <div
            style={{
                width:`${cardWidth}px`,
                backgroundImage:`url(${backgroundImage}`
            }}
            className="RaceCard"
            key={serieData.id}
        >
            <CurrentWeather />
            <UpdateTime />
            <Title />
            <Predictions />
            <Sessions />
        </div>
    ) : null
}
export default RaceCard