import React, {Fragment, useState, useEffect, useContext, useRef} from "react"
import "./RaceCard.css"
import {ConvertTimezone, RoundTimes} from "../../utils/DataFunctions"
import {
    Content,
    ParseWeatherForHourChart,
    ParseWeatherForMinuteChart,
    Spinner
} from "../../utils/UIFunctions"
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts"
import moment from "moment-timezone"

import {LocalTimezoneContext} from "../../utils/helpers/LocalTimezone"
import {MyTimeContext} from "../../utils/helpers/MyTime"
import {ShowGraphsContext} from "../../utils/helpers/ShowGraphs"
import {LocationContext} from "../../utils/helpers/Location"
import {SessionsContext} from "../../utils/helpers/Sessions"
import {WeatherContext} from "../../utils/helpers/Weather"
import {ShowMinutelyContext} from "../../utils/helpers/ShowMinutely"
// import {get_hourly} from "../../utils/Firebase"

const intensityThreshold = 0.1
const probabilityThreshold = 0.1

const Predictions = () => {
    const predictionStart = useRef(null)
    const predictionEnd = useRef(null)
    const [{showGraphs}] = useContext(ShowGraphsContext)
    const [{currentSession, selectedSession}] = useContext(SessionsContext)
    const [{hourlyWeather, minutelyWeather}, dispatchWeather] = useContext(
        WeatherContext
    )
    const [{showMinutely}] = useContext(ShowMinutelyContext)
    const [content, setContent] = useState(null)
    // const [{locationData}] = useContext(LocationContext)

    // useEffect(() => {
    //     if (predictionEnd && predictionStart && locationData && locationData.length>0) {
    //         let hourly=get_hourly({location:locationData.id,start:predictionStart,end:predictionEnd,provider:'DS'})
    //         console.log(JSON.stringify(hourly))
    //     }
    // },[predictionStart,predictionEnd,locationData])

    useEffect(() => {
        [predictionStart.current, predictionEnd.current] = RoundTimes(
            currentSession.start,
            currentSession.end
        )
        let sw = []
        hourlyWeather.forEach((h) => {
            h.time >= predictionStart.current &&
            h.time < predictionEnd.current &&
            sw.push(h)
        })
        dispatchWeather({type:"SET_SESSION", sessionWeather:sw})
    }, [
        selectedSession,
        currentSession,
        hourlyWeather,
        predictionEnd,
        predictionStart,
        dispatchWeather
    ])

    useEffect(() => {
        Content(document.location.hostname, "Predictions").then((newContent) => {
            setContent(newContent)
        })
    }, [])

    return showGraphs ? (
        minutelyWeather.length > 0 &&
        Math.max.apply(
            Math,
            minutelyWeather.map((o) => o.time)
        ) > currentSession.start &&
        showMinutely ? (
            <WeatherMinuteChart
                predictionStart={predictionStart.current}
                predictionEnd={predictionEnd.current}
                content={content || []}
            />
        ) : (
            <WeatherHourChart
                predictionStart={predictionStart.current}
                predictionEnd={predictionEnd.current}
                content={content || []}
            />
        )
    ) : (
        <WeatherText
            predictionStart={predictionStart.current}
            predictionEnd={predictionEnd.current}
            content={content || []}
        />
    )
}

const WeatherText = ({predictionStart, predictionEnd, content}) => {
    const [{myTime}] = useContext(MyTimeContext)
    const [{localTimezone}] = useContext(LocalTimezoneContext)
    const [{locationData}] = useContext(LocationContext)
    const [{currentSession}] = useContext(SessionsContext)
    const [{sessionWeather}] = useContext(WeatherContext)

    const WeatherTable = ({time, intensity, probability}) => {
        return (
            <Fragment>
        <span className={`WeatherDisplayTable`}>
          {myTime
              ? ConvertTimezone(time, "UTC", localTimezone).format("HH:mm")
              : ConvertTimezone(time, "UTC", locationData.timezone).format(
                  "HH:mm"
              )}
            &nbsp;-&nbsp;
            {myTime
                ? ConvertTimezone(time + 3600, "UTC", localTimezone).format("HH:mm")
                : ConvertTimezone(time + 3600, "UTC", locationData.timezone).format(
                    "HH:mm"
                )}
            :&nbsp;&nbsp;
        </span>
                <span className={`WeatherDisplayTable`}>
          {Math.round(intensity * 10) / 10} mm/h&nbsp;
        </span>
                <span className="WeatherDisplayTableLight">
          ({Math.round(probability * 100)}%&nbsp;
                    {content &&
                        content.length > 0 &&
                        content.find((o) => o.tag === "probability").content}
                    )
        </span>
                <br/>
            </Fragment>
        )
    }

    if (currentSession && sessionWeather) {
        return (
            <div className="Predictions">
                <PredictionsHeader
                    start={currentSession.start}
                    end={currentSession.end}
                    name={currentSession.long}
                />
                <hr className="WeatherDisplayHr"/>
                {sessionWeather.length > 0 &&
                    Math.max.apply(
                        Math,
                        sessionWeather.map((o) => o.intensity)
                    ) >= intensityThreshold &&
                    Math.max.apply(
                        Math,
                        sessionWeather.map((o) => o.probability)
                    ) >= probabilityThreshold &&
                    sessionWeather.map(
                        (i) =>
                            i.time >= predictionStart &&
                            i.time < predictionEnd &&
                            i.intensity >= intensityThreshold &&
                            i.probability >= probabilityThreshold && (
                                <WeatherTable
                                    time={i.time}
                                    intensity={i.intensity}
                                    probability={i.probability}
                                    key={i.time}
                                />
                            )
                    )}
                {sessionWeather.length > 0 &&
                    (Math.max.apply(
                            Math,
                            sessionWeather.map((o) => o.intensity)
                        ) < intensityThreshold ||
                        Math.max.apply(
                            Math,
                            sessionWeather.map((o) => o.probability)
                        ) < probabilityThreshold) && (
                        <span className="WeatherDisplayTable">
              {content &&
                  content.find((o) => o.tag === "noRainExpected").content}
            </span>
                    )}
                {sessionWeather.length === 0 && (
                    <span className="WeatherDisplayTableLight">
            {content &&
                content.length > 0 &&
                content.find((o) => o.tag === "noDataAvailable").content}
          </span>
                )}
            </div>
        )
    } else {
        return <Spinner/>
    }
}

const WeatherHourChart = ({predictionStart, predictionEnd, content}) => {
    const [graphData, setGraphData] = useState([])
    const [{myTime}] = useContext(MyTimeContext)
    const [{localTimezone}] = useContext(LocalTimezoneContext)
    const [{locationData}] = useContext(LocationContext)
    const [{currentSession}] = useContext(SessionsContext)
    const [{sessionWeather}] = useContext(WeatherContext)

    useEffect(() => {
        let newData = ParseWeatherForHourChart(
            sessionWeather,
            myTime ? localTimezone : locationData.timezone,
            intensityThreshold,
            probabilityThreshold,
            predictionStart,
            predictionEnd
        )
        setGraphData(newData)
    }, [
        sessionWeather,
        myTime,
        localTimezone,
        locationData,
        predictionStart,
        predictionEnd
    ])

    const CustomTooltip = ({active, payload}) => {
        if (active) {
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        fontFamily:`'Lato', sans-serif`,
                        fontWeight:300,
                        color:"white",
                        fontSize:"0.7em",
                        padding:"5px",
                        borderRadius:"10px 10px 10px 0",
                        backgroundColor:"rgba(65,105,225,0.5)",
                        border:"rgba(255,255,255,0.5) 2px dotted"
                    }}
                >
                    {Math.round(payload[0].payload.intensity * 10) / 10} mm/h
                    <br/>
                    {payload[0].payload.intensity > 0 &&
                        `${payload[0].payload.probability} ${
                            content && content.find((o) => o.tag === "probability").content
                        }`}
                </div>
            )
        }

        return null
    }

    if (currentSession && sessionWeather) {
        return (
            <div className="Predictions">
                <PredictionsHeader
                    start={currentSession.start}
                    end={currentSession.end}
                    name={currentSession.long}
                />
                {graphData.length > 0 && (
                    <ResponsiveContainer width="99%" height={130}>
                        <BarChart
                            margin={{left:-40, top:15, bottom:-10}}
                            data={graphData}
                        >
                            <XAxis
                                dataKey="time"
                                position="insideBottom"
                                tick={{
                                    fontSize:"0.7em",
                                    color:"#fff",
                                    fontFamily:`'Lato', sans-serif`,
                                    fontWeight:300
                                }}
                                stroke="#fff"
                                interval="preserveStartEnd"
                            />
                            <YAxis
                                dataKey="intensity"
                                tick={{
                                    fontSize:"0.7em",
                                    color:"#fff",
                                    fontFamily:`'Lato', sans-serif`,
                                    fontWeight:300
                                }}
                                stroke="#fff"
                                domain={[0, dataMax => (dataMax > 4 ? Math.ceil(dataMax / 4) * 4 : 4)]}
                                interval={0}
                                tickCount={3}
                            />
                            <CartesianGrid
                                strokeDasharray="5 5"
                                stroke="#aaa"
                                vertical={false}
                            />
                            <Tooltip content={<CustomTooltip/>} cursor={false}/>
                            <Bar dataKey="intensity">
                                {graphData.map((entry, index) => (
                                    <Cell fill="#4169e1" opacity={0.9} key={index}/>
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                )}
                {graphData.length === 0 && (
                    <Fragment>
                        <hr className="WeatherDisplayHr"/>
                        <span className="WeatherDisplayTableLight">
              {content &&
                  content.length > 0 &&
                  content.find((o) => o.tag === "noDataAvailable").content}
            </span>
                    </Fragment>
                )}
            </div>
        )
    } else {
        return <Spinner/>
    }
}

const WeatherMinuteChart = ({predictionStart, predictionEnd, content}) => {
    const [graphData, setGraphData] = useState([])
    const [{myTime}] = useContext(MyTimeContext)
    const [{localTimezone}] = useContext(LocalTimezoneContext)
    const [{locationData}] = useContext(LocationContext)
    const [{currentSession}] = useContext(SessionsContext)
    const [{minutelyWeather}] = useContext(WeatherContext)

    useEffect(() => {
        let minutelySessionWeather = []
        minutelyWeather.forEach((m) => {
            if (
                m.time >= predictionStart &&
                m.time < predictionEnd &&
                m.time >= Number(moment.tz("UTC").format("X"))
            ) {
                minutelySessionWeather.push(m)
            }
        })
        let newData = ParseWeatherForMinuteChart(
            minutelySessionWeather,
            myTime ? localTimezone : locationData.timezone,
            intensityThreshold,
            probabilityThreshold,
            predictionStart,
            predictionEnd
        )
        setGraphData(newData)
        let wacht = setTimeout(() => {
            let herhaal = setInterval(() => {
                minutelySessionWeather = []
                minutelyWeather.forEach((m) => {
                    if (
                        m.time >= predictionStart &&
                        m.time < predictionEnd &&
                        m.time >= Number(moment.tz("UTC").format("X"))
                    ) {
                        minutelySessionWeather.push(m)
                    }
                })
                let newData = ParseWeatherForMinuteChart(
                    minutelySessionWeather,
                    myTime ? localTimezone : locationData.timezone,
                    intensityThreshold,
                    probabilityThreshold,
                    predictionStart,
                    predictionEnd
                )
                setGraphData(newData)
            }, 15000)
            return () => {
                clearInterval(herhaal)
            }
        }, 30000)
        return () => {
            clearTimeout(wacht)
        }
    }, [
        myTime,
        localTimezone,
        locationData,
        predictionStart,
        predictionEnd,
        minutelyWeather
    ])

    const CustomTooltip = ({active, payload}) => {
        if (active) {
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        fontFamily:`'Lato', sans-serif`,
                        fontWeight:300,
                        color:"white",
                        fontSize:"0.7em",
                        padding:"5px",
                        borderRadius:"10px 10px 10px 0",
                        backgroundColor:"rgba(65,105,225,0.5)",
                        border:"rgba(255,255,255,0.5) 2px dotted"
                    }}
                >
                    {Math.round(payload[0].payload.intensity * 10) / 10} mm/h
                    <br/>
                    {payload[0].payload.intensity > 0 &&
                        `${payload[0].payload.probability} ${
                            content && content.find((o) => o.tag === "probability").content
                        }`}
                </div>
            )
        }

        return null
    }

    if (currentSession && minutelyWeather) {
        return (
            <div className="Predictions">
                <PredictionsHeader
                    start={currentSession.start}
                    end={currentSession.end}
                    name={currentSession.long}
                />
                {graphData.length > 0 && (
                    <ResponsiveContainer width="99%" height={130}>
                        <BarChart
                            margin={{left:-40, top:15, bottom:-10}}
                            data={graphData}
                        >
                            <XAxis
                                dataKey="time"
                                position="insideBottom"
                                tick={{
                                    fontSize:"0.7em",
                                    color:"#fff",
                                    fontFamily:`'Lato', sans-serif`,
                                    fontWeight:300
                                }}
                                stroke="#fff"
                                interval="preserveStartEnd"
                            />
                            <YAxis
                                dataKey="intensity"
                                tick={{
                                    fontSize:"0.7em",
                                    color:"#fff",
                                    fontFamily:`'Lato', sans-serif`,
                                    fontWeight:300
                                }}
                                stroke="#fff"
                                domain={[0, dataMax => (dataMax > 4 ? dataMax : 4)]}
                                interval={0}
                                tickCount={3}
                            />
                            <CartesianGrid
                                strokeDasharray="5 5"
                                stroke="#aaa"
                                vertical={false}
                            />
                            <Tooltip content={<CustomTooltip/>} cursor={false}/>
                            <Bar dataKey="intensity">
                                {graphData.map((entry, index) => (
                                    <Cell fill="#4169e1" opacity={0.9} key={index}/>
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                )}
                {graphData.length === 0 && (
                    <Fragment>
                        <hr className="WeatherDisplayHr"/>
                        <span className="WeatherDisplayTableLight">
              {content &&
                  content.length > 0 &&
                  content.find((o) => o.tag === "noDataAvailable").content}
            </span>
                    </Fragment>
                )}
            </div>
        )
    } else {
        return <Spinner/>
    }
}

const PredictionsHeader = ({content}) => {
    const [{myTime}] = useContext(MyTimeContext)
    const [{localTimezone}] = useContext(LocalTimezoneContext)
    const [flashTimes, setFlashTimes] = useState(null)
    const [flashName, setFlashName] = useState(false)
    const [{locationData}] = useContext(LocationContext)
    const [{currentSession}] = useContext(SessionsContext)
    const [{minutelyWeather}] = useContext(WeatherContext)
    const [{showMinutely}, dispatchShowMinutely] = useContext(
        ShowMinutelyContext
    )

    useEffect(() => {
        setFlashTimes(true)
        let wacht = setTimeout(() => {
            setFlashTimes(null)
        }, 3000)
        return () => {
            clearTimeout(wacht)
        }
    }, [myTime, currentSession])

    useEffect(() => {
        setFlashName(true)
        let wacht = setTimeout(() => {
            setFlashName(false)
        }, 3000)
        return () => {
            clearTimeout(wacht)
        }
    }, [currentSession.short])

    return (
        <Fragment>
      <span
          className={`PredictionsTimes ${flashTimes ? "FlashThis" : undefined}`}
      >
        {myTime
            ? ConvertTimezone(currentSession.start, "UTC", localTimezone).format(
                "DD-MM HH:mm"
            )
            : ConvertTimezone(
                currentSession.start,
                "UTC",
                locationData.timezone
            ).format("DD-MM HH:mm")}
          &nbsp;-&nbsp;
          {myTime
              ? ConvertTimezone(currentSession.end, "UTC", localTimezone).format(
                  "HH:mm"
              )
              : ConvertTimezone(
                  currentSession.end,
                  "UTC",
                  locationData.timezone
              ).format("HH:mm")}
          :&nbsp;
      </span>
            <span
                className={`PredictionsSession ${flashName ? "FlashThis" : undefined}`}
            >
        {currentSession.long}
      </span>
            <span
                className="PredictionsZoom"
                onClick={() => dispatchShowMinutely({type:"TOGGLE"})}
                title={
                    content &&
                    content.find((o) => o.tag === "toggleShowMinutelyContent").content
                }
            >
        {minutelyWeather.length > 0 &&
            Math.max.apply(
                Math,
                minutelyWeather.map((o) => o.time)
            ) > currentSession.start &&
            <i className={`fas fa-search-${showMinutely ? "minus" : "plus"}`}/>
        }
      </span>
        </Fragment>
    )
}

export default Predictions