import React, { useState, useEffect, useContext } from "react";
import "./RaceCard.css";
import moment from "moment-timezone";
import { Spinner, Content } from "../../utils/UIFunctions";

import { LocalTimezoneContext } from "../../utils/helpers/LocalTimezone";
import { MyTimeContext } from "../../utils/helpers/MyTime";
import { LocationContext } from "../../utils/helpers/Location";

const UpdateTime = () => {
  const [{ myTime }] = useContext(MyTimeContext);
  const [{ localTimezone }] = useContext(LocalTimezoneContext);
  const [flashThis, setFlashThis] = useState(false);
  const [{ locationData }] = useContext(LocationContext);
  const [content, setContent] = useState(null);

  useEffect(() => {
    Content(document.location.hostname, "UpdateTime").then((newContent) => {
      setContent(newContent);
    });
  }, []);

  useEffect(() => {
    setFlashThis(true);
    setTimeout(() => {
      setFlashThis(false);
    }, 3000);
  }, [locationData]);

  return locationData && locationData.WAweatherUpdate ? (
    <span
      className={`RaceCardUpdateTime ${flashThis ? "FlashThis" : undefined}`}
    >
      {content &&
        content.length > 0 &&
        content.find((o) => o.tag === "UpdateTime").content}
      :&nbsp;
      {locationData.WAweatherUpdate ? (
        moment
          .utc(locationData.WAweatherUpdate, "X")
          .tz(myTime ? localTimezone : locationData.timezone)
          .format("HH:mm")
      ) : (
        <Spinner />
      )}
    </span>
  ) : null;
};

export default UpdateTime;
