import React, { createContext, useReducer } from "react";

let WeekendReducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return { weekendData: action.weekend, serieData: action.serie };
    default:
      return state;
  }
};
const initialWeekend = { weekendData: [], serieData: [] };
const WeekendContext = createContext(initialWeekend);
const WeekendProvider = ({ children }) => {
  const [state, dispatch] = useReducer(WeekendReducer, initialWeekend);
  return (
    <WeekendContext.Provider value={[state, dispatch]}>
      {children}
    </WeekendContext.Provider>
  );
};

export { WeekendContext, WeekendProvider };
