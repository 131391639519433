import React, { useState, useEffect, useContext } from "react";
import "./RaceCard.css";
import { Spinner } from "../../utils/UIFunctions";
import { LocationContext } from "../../utils/helpers/Location";
import { WeatherIcon } from "../../utils/UIFunctions";
import { Content } from "../../utils/UIFunctions";
import { WeatherServiceContext } from "../../utils/helpers/WeatherService"

const CurrentWeather = () => {
  const [newIcon, setNewIcon] = useState(false);
  const [newTemp, setNewTemp] = useState(false);
  const [newUpdateTime, setNewUpdateTime] = useState(false);
  const [thisIcon, setThisIcon] = useState(null);
  const [thisTemp, setThisTemp] = useState(null);
  const [thisUpdateTime, setThisUpdateTime] = useState(null);
  const [{ locationData }] = useContext(LocationContext);
  const [content, setContent] = useState(null);
  const [{ weatherService }] = useContext(WeatherServiceContext)

  useEffect(() => {
    Content(document.location.hostname, "CurrentWeather").then((newContent) => {
      setContent(newContent);
    });
  }, []);

  useEffect(() => {
    if (newTemp) {
      setTimeout(() => {
        setNewTemp(false);
      }, 3000);
    }
    if (newIcon) {
      setTimeout(() => {
        setNewIcon(false);
      }, 3000);
    }
    if (newUpdateTime) {
      setTimeout(() => {
        setNewUpdateTime(false);
      }, 3000);
    }
  }, [newIcon, newTemp, newUpdateTime]);

  useEffect(() => {
    if (locationData && locationData.WAweatherIcon !== thisIcon) {
      setNewIcon(true);
      setThisIcon(locationData.WAweatherIcon);
    }
    if (locationData && locationData.WAweatherTemperature !== thisTemp) {
      setNewTemp(true);
      setThisTemp(locationData.WAweatherTemperature);
    }
    if (locationData && locationData.WAweatherUpdate !== thisUpdateTime) {
      setNewUpdateTime(true);
      setThisUpdateTime(locationData.WAweatherUpdate);
    }
  }, [locationData, thisIcon, thisTemp, thisUpdateTime, weatherService]);

  return (
    <div className="CurrentWeather">
      <p className={`${newTemp ? "FlashThis" : undefined}`}>
        <span className="CurrentWeatherNow">
          {content &&
            content.find((o) => o.tag === "currentWeatherNow").content}
          :&nbsp;
        </span>
        {thisTemp ? Math.round(thisTemp) : <Spinner />}&deg;C&nbsp;&nbsp;
        <i
          className={`fas ${WeatherIcon(thisIcon) || <Spinner />} fa-lg  ${newIcon ? "FlashThis" : undefined
            }`}
        />
      </p>
    </div>
  );
};

export default CurrentWeather;
