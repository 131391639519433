import React, { createContext, useReducer } from "react";

let WeatherReducer = (state, action) => {
  switch (action.type) {
    case "SET_HOURLY":
      return { ...state, hourlyWeather: action.hourlyWeather };
    case "SET_MINUTELY":
      return { ...state, minutelyWeather: action.minutelyWeather };
    case "SET_SESSION":
      return { ...state, sessionWeather: action.sessionWeather };
    default:
      return state;
  }
};
const initialWeather = {
  hourlyWeather: [],
  minutelyWeather: [],
  sessionWeather: [],
};
const WeatherContext = createContext(initialWeather);
const WeatherProvider = ({ children }) => {
  const [state, dispatch] = useReducer(WeatherReducer, initialWeather);
  return (
    <WeatherContext.Provider value={[state, dispatch]}>
      {children}
    </WeatherContext.Provider>
  );
};

export { WeatherContext, WeatherProvider };
